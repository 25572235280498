import { useState } from 'react';
import { IInputProps } from 'shared/types/Interfaces/IInputProps';
import { cn } from 'utils/classnames';

import eye from '../../../assets/icons/eye.svg';
import Input from '../Input/Input';

import styles from './inputPassword.module.scss';

type TInputPasswordProps = Omit<IInputProps, 'type'>;

function InputPassword({ id, name, placeholder, value, onChange, onBlur, error, className }: TInputPasswordProps) {
  const [isVisible, setIsVisible] = useState(false);

  const showPassword = () => setIsVisible((prev) => !prev);

  return (
    <div className={cn(styles.container, className)}>
      <Input
        className={styles.input}
        type={isVisible ? 'text' : 'password'}
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={error}
      />
      <div className={styles.eye} onClick={showPassword}>
        {isVisible ? 'Скрыть пароль' : 'Показать пароль'}&nbsp;
        <img src={eye} alt='eye' />
      </div>
    </div>
  );
}

export default InputPassword;
