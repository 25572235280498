import { RangePickerProps } from 'antd/es/date-picker';
import { useAppDispatch } from 'app/GlobalRedux/store';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';
import {
  useBlockUserMutation,
  useChangeUserMutation,
  useGetUser2FAQuery,
  useGetUserQuery,
  userApi,
  useRemoveUser2FAMutation,
} from 'widgets/User';

export function useUserActionsLogic() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { showError, showSuccess } = useAppMessage();
  const [isBlockUserModalOpen, setIsBlockUserModalOpen] = useState(false);
  const [blockedUntil, setBlockedUntil] = useState<Dayjs | null | undefined>();

  const [blockUser, blockUserResponse] = useBlockUserMutation();
  const [removeUser2FA] = useRemoveUser2FAMutation();
  const [putNewUserData] = useChangeUserMutation();

  const {
    data: userData,
    isLoading: isUserDataLoading,
    refetch,
  } = useGetUserQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });

  const { data: user2FAData, isLoading: isUser2FADataLoading } = useGetUser2FAQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });

  async function handleBlockUser(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (params.id === undefined) return showError({ error: 'User id is undefined' } as IResponseError);

    try {
      await blockUser({
        panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        userId: params.id,
        body: {
          blockedUntil,
        },
      });

      refetch();

      showSuccess('Пользователь заблокирован');
      setIsBlockUserModalOpen(false);
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  function openBlockUserModal() {
    setIsBlockUserModalOpen(true);
  }

  async function handleUnblockUser() {
    try {
      await putNewUserData({
        body: { blockedUntil: null },
        panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        userId: userData?.data?.userId ?? 'frontend error',
      }).unwrap();

      showSuccess('Пользователь разблокирован');
      dispatch(userApi.util.invalidateTags(['User']));
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  function handleRemoveUser2FA() {
    if (params.id === undefined) return showError({ error: 'User id is undefined' } as IResponseError);

    removeUser2FA({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      userId: params.id,
    });
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  return {
    handleBlockUser,
    handleRemoveUser2FA,
    isBlockUserModalOpen,
    setIsBlockUserModalOpen,
    blockUserResponse,
    blockedUntil,
    setBlockedUntil,
    userData,
    disabledDate,
    handleUnblockUser,
    user2FAData,
    isUser2FADataLoading,
    openBlockUserModal,
    isUserDataLoading,
  };
}
