import { type FC } from 'react';
import { IInputProps } from 'shared/types/Interfaces/IInputProps';

import Error from '../Error/Error';

import styles from './input.module.scss';

interface Props extends IInputProps {
  bind?: (input: HTMLInputElement) => void;
}

const Input: FC<Props> = ({ className, error, bind, ...props }) => {
  const combinedClassName = `${error ? styles.input_error : styles.input} ${className || ''}`;

  return (
    <>
      <input ref={bind} className={combinedClassName} {...props} />
      {error && <Error error={error} />}
    </>
  );
};

export default Input;
