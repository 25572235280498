import { Descriptions } from 'antd';
import { IPaymentCodes } from 'widgets/User';

import styles from './paymentCodes.module.scss';

export function PaymentCodeDescription({ codeInfo }: { codeInfo: IPaymentCodes }) {
  return (
    <Descriptions title={`Платежный код ${codeInfo.paymentCode}`} column={2} size='small' className={styles.description}>
      <Descriptions.Item label='Amount'>{codeInfo.amount}</Descriptions.Item>
      <Descriptions.Item label='City id'>{codeInfo.cityId}</Descriptions.Item>
      <Descriptions.Item label='City name'>{codeInfo.cityName}</Descriptions.Item>
      <Descriptions.Item label='Country codeInfo'>{codeInfo.countryCode}</Descriptions.Item>
      <Descriptions.Item label='Country name'>{codeInfo.countryName}</Descriptions.Item>
      <Descriptions.Item label='Created'>{codeInfo.created_at}</Descriptions.Item>
      <Descriptions.Item label='Currency codeInfo'>{codeInfo.currencyCode}</Descriptions.Item>
      <Descriptions.Item label='Currency name'>{codeInfo.currencyName}</Descriptions.Item>
      <Descriptions.Item label='Payment codeInfo'>{codeInfo.paymentCode}</Descriptions.Item>
      <Descriptions.Item label='Rancid at'>{codeInfo.rancid_at}</Descriptions.Item>
      <Descriptions.Item label='Status'>{codeInfo.status}</Descriptions.Item>
      <Descriptions.Item label='TransactionId'>{codeInfo.transactionId}</Descriptions.Item>
    </Descriptions>
  );
}
