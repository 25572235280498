import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { IMarkupRequest, IMarkupResponse, IMarkupsResponse, TMarkupsRequest } from '../model/types';

export const markupTableApi = createApi({
  reducerPath: 'markupTableApi',
  baseQuery,
  endpoints: (builder) => ({
    getMarkups: builder.query<IMarkupsResponse, TMarkupsRequest>({
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[markupId]': params.markupId,
          'filter[rateId]': params.rateId,
          'filter[rateFrom]': params.rateFrom,
          'filter[rateTo]': params.rateTo,
          'filter[operation]': params.operation,
          'filter[isPercent]': params.isPercent,
          'filter[isEnabled]': params.isEnabled,
        };

        return {
          url: `/${params.panelType}/markups`,
          params: updatedParams,
        };
      },
    }),
    putMarkup: builder.mutation<IMarkupResponse, IMarkupRequest>({
      query: ({ panelType, markupId, body }) => {
        return {
          url: `/${panelType}/markups/${markupId}`,
          method: 'PUT',
          body,
        };
      },
    }),
  }),
});

export const { useGetMarkupsQuery, usePutMarkupMutation } = markupTableApi;
