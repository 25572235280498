import { Col, Row } from 'antd';
import Card from 'antd/es/card/Card';
import FormItem from 'antd/es/form/FormItem';
import { ChangeUserEmail } from 'features/ChangeUserEmail';
import { ChangeUserPassword } from 'features/ChangeUserPassword';
import { ChangeUserPhone } from 'features/ChangeUserPhone';
import { ChangeUserTelegram } from 'features/ChangeUserTelegram';
import { GetKYCPassportBtn } from 'features/GetKYCPassportBtn';
import { GetKYCPhotoBtn } from 'features/GetKYCPhotoBtn';
import { Button } from 'shared/ui/Button/Button';

import styles from './firstSection.module.scss';

export function FirstSection() {
  return (
    <Card className={styles.contacts_form}>
      <ChangeUserEmail />
      <Row gutter={16}>
        <Col span={8}>
          <ChangeUserPhone />
        </Col>
        <Col span={16}>
          <ChangeUserPassword />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <ChangeUserTelegram />
        </Col>
        <Col span={8}>
          <FormItem label='KYC' className={styles.form_item}>
            <div className={styles.kyc_document_btns}>
              <GetKYCPassportBtn />
              <GetKYCPhotoBtn />
            </div>
          </FormItem>
        </Col>
      </Row>
    </Card>
  );
}
