import telegram from '../../../../assets/icons/tg.svg';

import styles from './footer.module.scss';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.links}>
          <a href={'https://t.me/delta_wallet'} target='_blank'>
            <img src={telegram} alt='telegram icon' />
            delta_wallet
          </a>
          <a href={'/privacy-policy'}>Privacy Policy</a>
          <a href={'/terms-and-conditions'}>Service Agreement</a>
        </div>
        <div className={styles.copyrights}>© 2023 TERRA-NOVA. All rights reserved</div>
      </div>
    </footer>
  );
}

export default Footer;
