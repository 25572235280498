import { useAuth, useSignInMutation } from 'entities/Auth';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { encodeToBase64 } from 'utils/encodeToBase64';
import { useAppMessage } from 'utils/messageContext';
import * as yup from 'yup';

function useLoginForm() {
  const navigate = useNavigate();
  const [signIn] = useSignInMutation();
  const { setToken } = useAuth();
  const { showError } = useAppMessage();

  const loginForm = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: yup.object({
      login: yup.string().required('Required field'),
      password: yup.string().required('Required field'),
    }),
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const response = await signIn({
          ...values,
          password: encodeToBase64(values.password),
        }).unwrap();
        if (response.token) {
          navigate('/');
          setToken(response.token);
        }
      } catch (error) {
        showError(error as IResponseError);
      }
    },
  });

  return {
    loginForm,
  };
}

export default useLoginForm;
