export function groupArrByColNumber(arr: any[], colNumber: number) {
  const colArr = [];

  for (let i = 0; i < arr.length; i += colNumber) {
    const row = arr.slice(i, i + colNumber);
    colArr.push(row);
  }

  return colArr;
}
