import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { IPutRateRequest, IPutRateResponse, IRatesRequest, IRatesResponse } from '../model/types';

export const ratesApi = createApi({
  reducerPath: 'ratesApi',
  tagTypes: ['Rates'],
  baseQuery,
  endpoints: (builder) => ({
    getRates: builder.query<IRatesResponse, IRatesRequest>({
      providesTags: ['Rates'],
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[rateId]': params.rateId,
          'filter[currencyCodeFrom]': params.currencyCodeFrom,
          'filter[currencyCodeTo]': params.currencyCodeTo,
          'filter[isManual]': params.isManual,
          'filter[isEnabled]': params.isEnabled,
        };

        return {
          url: `/${params.panelType}/rates/`,
          params: updatedParams,
        };
      },
    }),
    getRatesCount: builder.query<{ total: number }, IRatesRequest>({
      query: (params) => {
        const updatedParams = {
          'filter[rateId]': params.rateId,
          'filter[currencyCodeFrom]': params.currencyCodeFrom,
          'filter[currencyCodeTo]': params.currencyCodeTo,
          'filter[isManual]': params.isManual,
          'filter[isEnabled]': params.isEnabled,
        };

        return {
          url: `/${params.panelType}/rates/count`,
          params: updatedParams,
        };
      },
    }),
    putRate: builder.mutation<IPutRateResponse, IPutRateRequest>({
      query: ({ panelType, rateId, body }) => ({
        url: `/${panelType}/rates/${rateId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Rates'],
    }),
  }),
});

export const { useGetRatesQuery, useLazyGetRatesQuery, useLazyGetRatesCountQuery, usePutRateMutation } = ratesApi;
