import { Card, Checkbox, Col, DatePicker, Flex, Form, Input, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { Button } from 'shared/ui/Button/Button';

import { useFiltersLogic } from '../../../model/useFiltersLogic';

import styles from './filters.module.scss';

export function Filters() {
  const { flow, state } = useFiltersLogic();

  return (
    <Card title='Фильтр'>
      <Form form={state.form} layout={'vertical'}>
        <Row gutter={16}>
          <Col span={8}>
            <FormItem label='ID' name='userId' className={styles.form_item}>
              <Input />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              name='email'
              label='Email'
              className={styles.form_item}
              rules={[
                { min: 3, message: 'Поле должно содержать не менее 3 символов' },
                { pattern: /^[\p{L}\d]/u, message: 'Поле должно начинаться с буквы или цифры' },
                {
                  validator: (_, value) => {
                    if (/\s/.test(value)) return Promise.reject(new Error('Поле не может содержать пробелы'));
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input onChange={flow.handleEmailFilterChange} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Profile ID' name='profileId' className={styles.form_item}>
              <Input />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <div className={styles.date_pickers}>
              <FormItem label='Создан с' name='createdFrom' className={styles.form_item}>
                <DatePicker showTime={{ format: 'HH:mm' }} format='YYYY-MM-DD HH:mm' placeholder='Выберите дату' />
              </FormItem>
              <FormItem label='Создан по' name='createdTo' className={styles.form_item}>
                <DatePicker showTime={{ format: 'HH:mm' }} format='YYYY-MM-DD HH:mm' placeholder='Выберите дату' />
              </FormItem>
            </div>
          </Col>
          <Col span={8} className={styles.checkbox}>
            <Checkbox checked={state.hasBalance} onChange={flow.showUsersWithBalance}>
              Отображать профили с балансом
            </Checkbox>
          </Col>
        </Row>
      </Form>

      <Flex gap={10} justify='flex-end'>
        <Button onClick={flow.applyFilters} disabled={state.hasError}>
          Применить
        </Button>
        <Button onClick={flow.resetFilters}>Отменить</Button>
      </Flex>
    </Card>
  );
}
