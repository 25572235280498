import { DefaultOptionType } from 'antd/es/select';
import { useGetCurrenciesQuery } from 'entities/Currencies';
import { useId } from 'react';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';

interface Props {
  value: string;
  onChange: ((value: string, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
}

export function CurrencyCodeSelector({ value, onChange }: Props) {
  const id = useId();
  const { data: currencies } = useGetCurrenciesQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
  });

  return (
    <CustomSelect
      id={id}
      value={value}
      onChange={onChange}
      emptyOption={{ value: '', label: 'Все' }}
      options={currencies?.list?.map((item) => ({ value: item?.currencyCode, label: item?.currencyCode }))}
      error={false}
    />
  );
}
