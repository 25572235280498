import { ACCESS_TOKEN } from 'entities/Auth';
import Cookies from 'js-cookie';

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseQuery = fetchBaseQuery({
  // baseUrl: 'https://admin-swagger.dev.c2c.payteza.com/mock/api/latest',
  // baseUrl: '/main/api/latest',
  baseUrl: `${process.env.REACT_APP_BASE_URL || '/main/api/latest'}`,
  prepareHeaders: (headers) => {
    // const localeCode = navigator.language.toUpperCase();
    // headers.set('localeCode', localeCode);

    const token = Cookies.get(ACCESS_TOKEN);
    if (token) {
      // для прода нужно Auth, для локалки Authorization. ПОтому что на серваке не получилось сделать едино
      headers.set(`${process.env.REACT_APP_TOKEN_HEADER || 'Auth'}`, token);
    }

    return headers;
  },
});
