import { FC } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { MAX_AMOUNT_LENGTH } from 'shared/constants';
import { IInputError } from 'shared/types/Interfaces/IInputProps';
import Input from 'shared/ui/Input/Input';
import { createNumberMask } from 'text-mask-addons';

const defaultMaskOptions = {
  prefix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  allowNegative: false,
  allowLeadingZeroes: false,
  integerLimit: MAX_AMOUNT_LENGTH,
};

type TDefaultMaskOptions = typeof defaultMaskOptions;

interface Props extends Omit<MaskedInputProps, 'render' | 'mask'> {
  error?: IInputError;
  mask?: Partial<TDefaultMaskOptions>;
}

const CurrencyInput: FC<Props> = ({ error, mask, ...props }) => {
  const maskConfig = createNumberMask({
    ...defaultMaskOptions,
    ...mask,
  });
  return (
    <MaskedInput
      mask={maskConfig}
      render={(ref, maskedProps) => (
        <Input
          bind={ref}
          error={error}
          {...maskedProps}
          onChange={(e) => {
            e.target.value = e.target.value.replace(/^[\.\,]/g, '');
            e.target.value = e.target.value.replace(/\,/g, '.');
            maskedProps.onChange?.(e);
          }}
        />
      )}
      {...props}
    />
  );
};

export default CurrencyInput;
