import { LoginForm } from 'features/LoginForm';

import styles from './login.module.scss';

export function LoginPage() {
  return (
    <div className={styles.container}>
      <LoginForm />
    </div>
  );
}
