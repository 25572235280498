import { Tabs, TabsProps } from 'antd';
import { useSearchParams } from 'react-router-dom';

import styles from './walletControlTabs.module.scss';

function WalletControlTabs({ tabs }: { tabs: TabsProps['items'] }) {
  const [searchParams] = useSearchParams();

  return <Tabs className={styles.tabs} defaultActiveKey='user' activeKey={searchParams.get('tab') ?? undefined} items={tabs} />;
}

export default WalletControlTabs;
