import { AdminsTable } from 'features/AdminsTable';
import { useState } from 'react';
import { Button } from 'shared/ui/Button/Button';
import Checkbox from 'shared/ui/Checkbox/Checkbox';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import Input from 'shared/ui/Input/Input';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';

import { UserAddOutlined } from '@ant-design/icons';

import fingerInCircle from '../../assets/icons/fingerInCircle.svg';

import styles from './adminsPage.module.scss';

export function AdminsPage() {
  const [addNewAdmin, setAddNewAdmin] = useState(false);

  return (
    <div className=''>
      <Button onClick={() => setAddNewAdmin(true)} className={styles.add_admin_btn}>
        <UserAddOutlined /> Add administrator
      </Button>
      <AdminsTable />

      <CustomModal width={560} className={styles.modal} isOpen={addNewAdmin} onClose={() => setAddNewAdmin(false)} centered>
        <ModalTitle>
          <img src={fingerInCircle} alt='finger in circle' />
          <span>Add a new administrator</span>
        </ModalTitle>
        <br />
        <form onSubmit={() => null}>
          <Input placeholder='Login' />
          <Input placeholder='Password' />
          <Checkbox onChange={() => null}>Is active</Checkbox>

          <Button className={styles.add_btn}>Add</Button>
        </form>
      </CustomModal>
    </div>
  );
}
