import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { createContext } from 'react';
import { NavLink } from 'react-router-dom';
import { round } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';
import { IUser } from 'widgets/User';

import { TUsersLogic, useUsersLogic } from '../../model/useUsersLogic';
import { Filters } from '../Filters/ui/Filters';
import { WalletsTooltip } from '../WalletsTooltip/ui/WalletsTooltip';

export const UsersContext = createContext<TUsersLogic | null>(null);

export function Users() {
  const logic = useUsersLogic();

  const columns: ColumnsType<IUser> = [
    {
      title: 'ID',
      dataIndex: 'userId',
      key: 'userId',
      sorter: (a, b) => +a.userId - +b.userId,
      sortOrder: logic.sortedInfo.columnKey === 'userId' ? logic.sortedInfo.order : null,
    },
    {
      title: 'Profile ID',
      dataIndex: 'profileId',
      key: 'profileId',
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => transformDateToUserFriendly(date),
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      sortOrder: logic.sortedInfo.columnKey === 'createdAt' ? logic.sortedInfo.order : null,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Баланс',
      dataIndex: 'amountUSD',
      key: 'amountUSD',
      render: (balance) => `${round(balance)} USD`,
      sorter: (a, b) => +a.amountUSD - +b.amountUSD,
      sortOrder: logic.sortedInfo.columnKey === 'amountUSD' ? logic.sortedInfo.order : null,
      width: '300px',
    },
    {
      title: 'Количество кошельков',
      dataIndex: 'wallets',
      key: 'wallets',
      render: (wallets: IUser['wallets'], record) => (
        <WalletsTooltip walletsWithBalance={logic.findAllEnabled(wallets)} userId={record.userId} />
      ),
    },
    {
      title: 'Действия',
      dataIndex: 'btn',
      key: 'btn',
      render: (_, record) => (
        <NavLink to={`${window.location.pathname}/${record.userId}?tab=user`}>
          <Button>Открыть</Button>
        </NavLink>
      ),
    },
  ];

  return (
    <UsersContext.Provider value={logic}>
      <Filters />

      <Table
        rowKey={(row) => row.userId}
        columns={columns}
        dataSource={logic.filteredData}
        loading={logic.isLoading || logic.isFetching}
        onChange={logic.handleChange}
        scroll={{ x: 'scroll' }}
        pagination={{
          position: ['topRight', 'bottomRight'],
          showSizeChanger: true,
          current: logic.currentPage,
          pageSize: logic.perPage,
          onShowSizeChange: (currentPage, amount) => {
            logic.setCurrentPage(currentPage);
            logic.setPerPage(amount);
          },
          total: logic.usersCount?.total ?? 0,
          showTotal: (value) => `Всего пользователей: ${value}`,
          locale: { items_per_page: 'на стр.' },
        }}
      />
    </UsersContext.Provider>
  );
}
