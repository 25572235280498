import { AccessResponse } from '..';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: AccessResponse = {
  isLoggedIn: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthority: (state, { payload }: PayloadAction<AccessResponse>) => {
      state.isLoggedIn = payload.isLoggedIn;
    },
  },
});

export const { setAuthority } = authSlice.actions;
export default authSlice.reducer;
