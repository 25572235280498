import dayjs, { Dayjs } from 'dayjs';
import { usePutTransactionCourierMutation } from 'entities/Transactions';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';
import { IPaymentCodes } from 'widgets/User';

export function usePaymentCodeCourierDescription(codeInfo: IPaymentCodes) {
  const params = useParams();
  const { showError, showSuccess } = useAppMessage();
  const [isDataPickerOpened, setIsDataPickerOpened] = useState(false);
  const [selectedCourierDate, setSelectedCourierDate] = useState<Dayjs>(dayjs(codeInfo.courierCallingAt));

  const [putTransactionCourier, putTransactionCourierResponse] = usePutTransactionCourierMutation();

  // Can not select days before today
  const disabledDate = (current: Dayjs | null): boolean => (current ? current.isBefore(dayjs().startOf('day')) : false);

  const confirmNewCourierDate = async () => {
    try {
      await putTransactionCourier({
        transactionId: params?.id ?? '',
        body: {
          courierCallingAt: selectedCourierDate?.toISOString() ?? '',
        },
      }).unwrap();

      showSuccess('Успех');
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  useEffect(() => {
    if (putTransactionCourierResponse.isSuccess) setIsDataPickerOpened(false);
  }, [putTransactionCourierResponse]);

  return {
    isDataPickerOpened,
    selectedCourierDate,
    disabledDate,
    setSelectedCourierDate,
    putTransactionCourierResponse,
    confirmNewCourierDate,
    setIsDataPickerOpened,
  };
}
