import { DefaultOptionType } from 'antd/es/select';
import { useGetTransactionTypesQuery } from 'entities/Transactions';
import { useId } from 'react';
import { transactionTypeMapping } from 'shared/constants';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';

interface Props {
  value: string;
  onChange: ((value: string, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
}

export function TransactionTypeSelector({ value, onChange }: Props) {
  const id = useId();
  const { data: transactionTypes } = useGetTransactionTypesQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
  });

  return (
    <CustomSelect
      id={id}
      value={value}
      onChange={onChange}
      emptyOption={{ value: '', label: 'Все' }}
      options={transactionTypes?.list?.map((item) => ({
        value: item,
        label: transactionTypeMapping[item as keyof typeof transactionTypeMapping],
      }))}
      error={false}
    />
  );
}
