import { Menu as AntMenu, MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { cn } from 'utils/classnames';

import * as AntdIcons from '@ant-design/icons';

import Burger from '../Burger/Burger';

import styles from './walletMenu.module.scss';

export function WalletMenu() {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const items: MenuProps['items'] = [
    {
      label: `${location.pathname.includes('wallet') ? 'Delta Wallet' : 'Delta Merchant'}`,
      key: 'deltaWallet',
      icon: React.createElement(AntdIcons.WalletOutlined),
      children: [
        {
          label: <NavLink to='/wallet/users'>Пользователи</NavLink>,
          key: 'users',
          icon: React.createElement(AntdIcons.UserOutlined),
          className: cn(location.pathname.includes('/wallet/users') && 'ant-menu-item ant-menu-item-selected'),
        },
        {
          label: <NavLink to='/wallet/finops'>Финансовые операции</NavLink>,
          key: 'finops',
          icon: React.createElement(AntdIcons.TransactionOutlined),
          className: cn(location.pathname.includes('/wallet/finops') && 'ant-menu-item ant-menu-item-selected'),
        },
        {
          label: <NavLink to={`/${location.pathname.includes('wallet') ? 'wallet' : 'merchant'}/admins`}>Администраторы</NavLink>,
          key: 'admins',
          icon: React.createElement(AntdIcons.SmileOutlined),
        },
      ],
    },
    {
      label: 'Агент',
      key: 'agent',
      icon: React.createElement(AntdIcons.TruckOutlined),
      children: [
        {
          key: 'offices',
          label: <NavLink to='/wallet/offices'>Офисы</NavLink>,
          icon: React.createElement(AntdIcons.ShopOutlined),
        },
        {
          key: 'couriers',
          label: <NavLink to='/wallet/couriers'>Курьеры</NavLink>,
          icon: React.createElement(AntdIcons.TruckOutlined),
        },
      ],
    },
    {
      label: 'Курсы валют',
      key: 'rates',
      icon: React.createElement(AntdIcons.RiseOutlined),
      children: [
        {
          key: 'fiat',
          label: <NavLink to='/wallet/fiat'>Фиат</NavLink>,
          icon: React.createElement(AntdIcons.RiseOutlined),
        },
        {
          key: 'crypto',
          label: <NavLink to='/wallet/crypto'>Крипто</NavLink>,
          icon: React.createElement(AntdIcons.DollarOutlined),
        },
      ],
    },
    {
      label: 'Кассы',
      key: 'cashDesc',
      icon: React.createElement(AntdIcons.MoneyCollectOutlined),
      children: [
        {
          key: 'cash',
          label: <NavLink to='wallet/cash'>Операции</NavLink>,
          icon: React.createElement(AntdIcons.TransactionOutlined),
        },
      ],
    },
    {
      label: 'Номенклатура',
      key: 'nomenclature',
      icon: React.createElement(AntdIcons.AppstoreOutlined),
      children: [
        {
          key: 'countries',
          label: <NavLink to='wallet/countries'>Страны</NavLink>,
          icon: React.createElement(AntdIcons.GlobalOutlined),
        },
        {
          key: 'currencies',
          label: <NavLink to='wallet/currencies'>Валюты</NavLink>,
          icon: React.createElement(AntdIcons.EuroCircleOutlined),
        },
        {
          key: 'locales',
          label: <NavLink to='wallet/locales'>Локали</NavLink>,
          icon: React.createElement(AntdIcons.EnvironmentOutlined),
        },
        {
          key: 'payMethods',
          label: <NavLink to='wallet/payMethods'>Платежные методы</NavLink>,
          icon: React.createElement(AntdIcons.CreditCardOutlined),
        },
      ],
    },
  ];

  return (
    <Sider collapsible collapsed={collapsed} className={styles.wrapper}>
      <div className={styles.menu_burger} onClick={() => setCollapsed((prev) => !prev)}>
        <Burger />
      </div>
      <AntMenu mode='vertical' className={styles.menu} items={items} selectedKeys={[location.pathname.split('/').at(-1) ?? 'users']} />
    </Sider>
  );
}
