import { useAppDispatch } from 'app/GlobalRedux/store';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppMessage } from 'utils/messageContext';
import { useChangeUserMutation, useGetUserQuery, userApi } from 'widgets/User';

export function useChangeUserTelegramLogic() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [newTelegram, setNewTelegram] = useState('');
  const [putNewUserData, putNewUserDataResponse] = useChangeUserMutation();
  const { showError, showSuccess } = useAppMessage();

  const { data } = useGetUserQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });

  const confirmChanges = () => {
    if (newTelegram === data?.data?.telegram) return showError({ data: { message: 'Внесите изменения' }, status: 100 });
    putNewUserData({
      body: {
        telegram: newTelegram,
      },
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      userId: data?.data?.userId ?? 'frontend error',
    });
  };

  useEffect(() => {
    setNewTelegram(data?.data?.telegram ?? '');
  }, [data]);

  useEffect(() => {
    if (!putNewUserDataResponse.isSuccess) return;

    showSuccess('Telegram изменен');
    dispatch(
      userApi.util.updateQueryData(
        'getUser',
        {
          panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
          userId: params.id ?? 'frontend error',
        },
        (draft) => {
          if (draft && draft.data) {
            draft.data.telegram = newTelegram; // Update the telegram field in the cache
          }
        }
      )
    );
  }, [putNewUserDataResponse]);

  return {
    data,
    newTelegram,
    confirmChanges,
    setNewTelegram,
  };
}
