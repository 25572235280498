import { Modal, ModalProps } from 'antd';
import { TClassName } from 'shared/types/Types/TClassName';

import styles from './customModal.module.scss';

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: TClassName;
  width?: number;
  centered?: boolean;
  getContainer?: ModalProps['getContainer'];
}

function CustomModal({ isOpen, onClose, children, width = 520, className, centered = false, getContainer = false }: CustomModalProps) {
  const combinedClassName = `${styles.modal} ${className || ''}`;

  return (
    <Modal
      centered={centered}
      getContainer={getContainer}
      width={width}
      open={isOpen}
      footer={null}
      onCancel={onClose}
      className={combinedClassName}
      destroyOnClose={true}
    >
      {children}
    </Modal>
  );
}

export default CustomModal;
