import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { IUsersRequest, IUsersResponse } from '../model/types';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery,
  tagTypes: ['GetUsers'],
  endpoints: (builder) => ({
    getUsers: builder.query<IUsersResponse, IUsersRequest>({
      providesTags: ['GetUsers'],
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[email]': params.email,
          'filter[userId]': params.userId,
          'filter[createdFrom]': params.createdFrom,
          'filter[createdTo]': params.createdTo,
          'filter[hasBalance]': params.hasBalance,
          'filter[profileId]': params.profileId,
        };

        return {
          url: `/${params.panelType}/users/`,
          params: updatedParams,
        };
      },
    }),
    getUsersCount: builder.query<{ total: number }, IUsersRequest>({
      query: (params) => {
        const updatedParams = {
          'filter[email]': params.email,
          'filter[userId]': params.userId,
          'filter[createdFrom]': params.createdFrom,
          'filter[createdTo]': params.createdTo,
          'filter[hasBalance]': params.hasBalance,
          'filter[profileId]': params.profileId,
        };

        return {
          url: `/${params.panelType}/users/count`,
          params: updatedParams,
        };
      },
    }),
  }),
});

export const { useGetUsersQuery, useLazyGetUsersQuery, useLazyGetUsersCountQuery, useGetUsersCountQuery } = usersApi;
