import { Card, Col, Form, Row, Skeleton } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { ITransactionInfo } from 'entities/Transactions';
import { ChangeFinOperationStatusWindow } from 'features/FinOperationsTable';
import { changeableStatuses } from 'features/FinOperationsTable';
import { NavLink, useNavigate } from 'react-router-dom';
import { operationTypeMapping, round, transactionStatusMapping, transactionTypeMapping } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';
import Input from 'shared/ui/Input/Input';
import { cn } from 'utils/classnames';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import { useFinOperationLogic } from '../../model/useFinOperationLogic';

import styles from './finOperation.module.scss';

const transactionInfoLabels: { [key in keyof ITransactionInfo]?: string } = {
  createdAt: 'Создано',
  updatedAt: 'Изменено',
  status: 'Статус заявки',
  address: 'Криптовалютный адрес',
  amount: 'Сумма',
  amountFrom: 'Сумма источника',
  amountTo: 'Сумма назначения',
  cityId: 'ID города',
  cityName: 'Название города',
  commission: 'Комиссия операции',
  countryCode: 'Код страны',
  countryName: 'Название страны',
  courierCallingAt: 'Курьер вызван на:',
  courierId: 'ID курьера',
  currencyCode: 'Код валюты',
  currencyName: 'Название валюты',
  currencyCodeFrom: 'Валюта источника',
  currencyCodeFromName: 'Название валюты источника',
  currencyCodeTo: 'Валюта назначения',
  currencyCodeToName: 'Название валюты назначения',
  iban: 'IBAN',
  network: 'Блокчейн сеть',
  officeId: 'ID офиса',
  operationType: 'Тип операции',
  paymentAddress: 'Банковская карта / телефон',
  paymentCode: 'Платежный код',
  paymentSystem: 'Платежная система',
  rancidAt: 'Действительно до',
  recipient: 'Получатель',
  state: 'Состояние криптовалютной заявки',
  system: 'Платежная система',
  systemExternalId: 'ID во внешней платежной системе',
  systemId: 'ID внешней платежной системы',
  userId: 'ID пользователя',
};

const highlightedParamKeys = ['iban', 'paymentAddress', 'paymentCode', 'recipient'];

export function FinOperation() {
  const logic = useFinOperationLogic();
  const navigate = useNavigate();

  const infoRows = logic.info.map((row, index) => (
    <Row key={index} gutter={16}>
      {row.map((col, i) => (
        <Col key={`row_${index}_col_${i}`} span={6}>
          <FormItem label={transactionInfoLabels[col[0] as keyof ITransactionInfo] ?? col[0]} className={styles.form_item}>
            <Input value={col[1]} readOnly disabled className={cn({ [styles.input_highlighted]: highlightedParamKeys.includes(col[0]) })} />
          </FormItem>
        </Col>
      ))}
    </Row>
  ));

  return logic.isLoading || logic.isFetching || logic.isInfoLoading || logic.isInfoFetching ? (
    <Skeleton active />
  ) : (
    <div className={styles.wrapper}>
      <Card title='Информация'>
        <Form layout='vertical' className={styles.form}>
          <Row gutter={16}>
            <Col span={6}>
              <FormItem label='Transaction ID (UID)' className={styles.form_item}>
                <Input value={logic.data?.transactionId ?? ''} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='User ID' className={cn(styles.form_item, styles.form_item_with_btn)}>
                <Input value={logic.data?.userId ?? ''} readOnly disabled />
                <NavLink
                  to={`/wallet/users/${logic.data?.userId}?tab=user`}
                  className={cn(styles.form_item_btn, logic.data?.userId === null && styles.disabled)}
                >
                  <Button>Перейти</Button>
                </NavLink>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Wallet ID' className={styles.form_item}>
                <Input value={logic.data?.walletId ?? ''} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Код валюты' className={styles.form_item}>
                <Input value={logic.data?.currencyCode ?? ''} readOnly disabled />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <FormItem label='Наименование валюты' className={styles.form_item}>
                <Input value={logic.data?.currencyName ?? ''} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Сумма' className={styles.form_item}>
                <Input value={round(logic.data?.amount ?? '1')} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Тип операции' className={styles.form_item}>
                <Input value={operationTypeMapping[logic.data?.operationType as keyof typeof operationTypeMapping] ?? ''} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Связанные транзакции' className={cn(styles.form_item, styles.form_item_with_btn)}>
                <Input value={logic.data?.linkedTransactionId ?? ''} readOnly disabled />
                <NavLink
                  to={`/wallet/finops/${logic.data?.linkedTransactionId}`}
                  className={cn(styles.form_item_btn, logic.data?.linkedTransactionId === null && styles.disabled)}
                >
                  <Button>Перейти</Button>
                </NavLink>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <FormItem label='Статус' className={cn(styles.form_item, styles.form_item_with_btn)}>
                <Input value={transactionStatusMapping[logic.data?.status as keyof typeof transactionStatusMapping] ?? ''} readOnly disabled />
                {logic.data?.status && (
                  <Button
                    disabled={!changeableStatuses.includes(logic.data.status)}
                    className={styles.form_item_btn}
                    onClick={logic.openChangeStatusWindow}
                  >
                    Изменить
                  </Button>
                )}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Дата создания' className={styles.form_item}>
                <Input value={transformDateToUserFriendly(logic.data?.createdAt ?? '')} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Дата изменения' className={styles.form_item}>
                <Input value={transformDateToUserFriendly(logic.data?.updatedAt ?? '')} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Взаимодействует с Wallet ID' className={styles.form_item}>
                <Input value={logic.data?.transferWalletId ?? ''} readOnly disabled />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <FormItem label='Взаимодействует с User ID' className={styles.form_item}>
                <Input value={logic.data?.transferUserId ?? ''} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Платежный метод' className={styles.form_item}>
                <Input value={String(logic.data?.payMethod)} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Вручную' className={styles.form_item}>
                <Input value={String(logic.data?.isManual)} readOnly disabled />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label='Платежная система' className={styles.form_item}>
                <Input value={logic.data?.paySystem ?? ''} readOnly disabled />
              </FormItem>
            </Col>
          </Row>
        </Form>

        <ChangeFinOperationStatusWindow
          isOpened={Boolean(logic.editTransactionId)}
          onClose={() => logic.setEditTransactionId(null)}
          onSubmit={logic.changeTransactionStatus}
          editTransactionId={logic.editTransactionId ?? 'no id'}
          selectedStatus={logic.transactionStatus ?? 'no status'}
          onChange={(value) => logic.setTransactionStatus(value)}
        />
      </Card>

      {infoRows.length ? (
        <Card title='Дополнительно'>
          <Form layout='vertical' className={styles.form}>
            {infoRows}
          </Form>
        </Card>
      ) : null}

      <Card title='Комментарий'>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem label='' className={cn(styles.form_item, styles.text_area)}>
              <TextArea value={logic.newComment} onChange={(e) => logic.setNewComment(e.target.value)} />
            </FormItem>
          </Col>
          <Col span={8}>
            <div className={styles.comment_btn}>
              <Button className={cn(logic.oldComment === logic.newComment && styles.disabled)} onClick={logic.changeTransactionComment}>
                Сохранить
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
      <div className={styles.control_btns}>
        <Button onClick={() => navigate(-1)}>Назад</Button>
      </div>
    </div>
  );
}
