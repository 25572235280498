import { useAppDispatch } from 'app/GlobalRedux/store';
import { useGetLocalesQuery } from 'entities/Locales';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';
import { IUserResponse, useChangeUserMutation, useGetUserBalanceQuery, useGetUserQuery, userApi } from 'widgets/User';

function useUserPersonalDataLogic() {
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [userData, setUserData] = useState<IUserResponse['data'] & { password?: string }>();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const { showError, showSuccess } = useAppMessage();

  const { data, isLoading, isError, error } = useGetUserQuery(
    {
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      userId: params.id ?? 'frontend error',
    },
    { pollingInterval: 60000 }
  );

  const { data: locales } = useGetLocalesQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    page: 1,
    perPage: 300,
  });

  const {
    data: balanceData,
    isLoading: isBalanceLoading,
    isError: isBalanceError,
    error: balanceError,
  } = useGetUserBalanceQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });

  const [putNewUserData] = useChangeUserMutation();

  function changeUserData(e: React.ChangeEvent<HTMLInputElement>) {
    setUserData((prev) => {
      if (prev === undefined) return;

      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });

    setIsFormChanged(true);
  }

  async function putUserData() {
    if (isFormChanged === false) return showError({ data: { message: 'The data remains the same' }, status: 100 });

    try {
      const response = await putNewUserData({
        body: {
          name: userData?.name,
          surname: userData?.surname,
          town: userData?.town,
          address: userData?.address,
          //TODO after changing swagger, change type for lang/langCode
          //@ts-ignore
          langCode: userData?.lang,
          countryCode: userData?.countryCode,
          isEnabled: userData?.isEnabled,
        },
        panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        userId: params.id ?? 'frontend error',
      }).unwrap();

      if (response.error) return showError(response.error.data.errors[0] as IResponseError);

      showSuccess('Данные успешно обновлены');
      dispatch(userApi.util.invalidateTags(['User']));
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  useEffect(() => {
    if (data) setUserData(data.data);
  }, [data]);

  useEffect(() => {
    if (isError || isBalanceError) {
      showError((error ?? isBalanceError) as IResponseError);
    }
  }, [isError, error, isBalanceError, balanceError]);

  return {
    isLoading,
    data,
    userData,
    isBalanceLoading,
    changeUserData,
    balanceData,
    putUserData,
    isFormChanged,
    locales,
    setUserData,
  };
}

export default useUserPersonalDataLogic;
export type TUserPersonalData = ReturnType<typeof useUserPersonalDataLogic>;
