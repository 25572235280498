import { Descriptions } from 'antd';
import { IPaymentCodes } from 'widgets/User';

import styles from './paymentCodes.module.scss';

export function PaymentCodeOfficeDescription({ codeInfo }: { codeInfo: IPaymentCodes }) {
  return (
    <Descriptions title={`Офис`} column={2} size='small' className={styles.description}>
      <Descriptions.Item label='Office name'>{codeInfo.office.name}</Descriptions.Item>
      <Descriptions.Item label='Office address'>{codeInfo.office.address}</Descriptions.Item>
      <Descriptions.Item label='Country name'>{codeInfo.office.countryName}</Descriptions.Item>
      <Descriptions.Item label='City name'>{codeInfo.office.cityName}</Descriptions.Item>
    </Descriptions>
  );
}
