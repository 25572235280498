import { Card, Col, DatePicker, Form, Input, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { CurrencyCodeSelector } from 'features/CurrencyCodeSelector';
import { emptyStringToUndefined, operationTypeMapping, paySystemMapping, transactionStatusMapping } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';

import { cashFinops } from '../../model/consts/cashFinops';
import { useFiltersLogic } from '../../model/hooks/useFiltersLogic';

import styles from './filters.module.scss';

export function Filters() {
  const logic = useFiltersLogic();

  return (
    <Card title='Фильтр'>
      {window.location.pathname.includes('cash') ? (
        <Form layout={'vertical'}>
          <Row gutter={16}>
            <Col span={8}>
              <FormItem label='Платежный код' className={styles.form_item}>
                <Input value={logic.payMethodFilter} onChange={(e) => emptyStringToUndefined(e.target.value, logic.setPaymentCodeFilter)} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label='Тип операции' className={styles.form_item}>
                <CustomSelect
                  id={'finops-filter'}
                  value={logic.operationTypeFilter ?? 'Все'}
                  error={false}
                  options={Object.entries(cashFinops).map(([value, label]) => ({ value, label }))}
                  onChange={(value) => emptyStringToUndefined(value, logic.setOperationTypeFilter)}
                  className={styles.net_select}
                  emptyOption={{ value: '', label: 'Все' }}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label='Статус' className={styles.form_item}>
                <CustomSelect
                  id={'status-filter'}
                  className={styles.net_select}
                  value={logic.statusFilter ?? 'Все'}
                  onChange={(value) => emptyStringToUndefined(value, logic.setStatusFilter)}
                  emptyOption={{ value: '', label: 'Все' }}
                  options={logic.transactionStatuses?.list?.map((item) => ({
                    value: item,
                    label: transactionStatusMapping[item as keyof typeof transactionStatusMapping],
                  }))}
                  error={false}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      ) : (
        <Form layout={'vertical'}>
          <Row gutter={16}>
            <Col span={8}>
              <FormItem label='UID' className={styles.form_item}>
                <Input
                  value={logic.transactionIdFilter}
                  onChange={(e) => emptyStringToUndefined(e.target.value, logic.setTransactionIdFilter)}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label='User ID' className={styles.form_item}>
                <Input value={logic.userIdFilter} onChange={(e) => emptyStringToUndefined(e.target.value, logic.setUserIdFilter)} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label='Wallet ID' className={styles.form_item}>
                <Input value={logic.walletIdFilter} onChange={(e) => emptyStringToUndefined(e.target.value, logic.setWalletIdFilter)} />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <FormItem label='Код валюты' className={styles.form_item}>
                <CurrencyCodeSelector
                  value={logic.currencyCodeFilter ?? 'Все'}
                  onChange={(value) => emptyStringToUndefined(value, logic.setCurrencyCodeFilter)}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label='Тип операции' className={styles.form_item}>
                <CustomSelect
                  id={'finops-filter'}
                  className={styles.net_select}
                  value={logic.operationTypeFilter ?? 'Все'}
                  onChange={(value) => emptyStringToUndefined(value, logic.setOperationTypeFilter)}
                  emptyOption={{ value: '', label: 'Все' }}
                  options={logic.finops?.list?.map((item) => ({
                    value: item,
                    label: operationTypeMapping[item as keyof typeof operationTypeMapping],
                  }))}
                  error={false}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label='Статус' className={styles.form_item}>
                <CustomSelect
                  id={'status-filter'}
                  className={styles.net_select}
                  value={logic.statusFilter ?? 'Все'}
                  onChange={(value) => emptyStringToUndefined(value, logic.setStatusFilter)}
                  emptyOption={{ value: '', label: 'Все' }}
                  options={logic.transactionStatuses?.list?.map((item) => ({
                    value: item,
                    label: transactionStatusMapping[item as keyof typeof transactionStatusMapping],
                  }))}
                  error={false}
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <FormItem label='Платежная система' className={styles.form_item}>
                <CustomSelect
                  id={'pay-system-filter'}
                  className={styles.net_select}
                  value={logic.paySystemFilter ?? 'Все'}
                  onChange={(value) => emptyStringToUndefined(value, logic.setPaySystemFilter)}
                  emptyOption={{ value: '', label: 'Все' }}
                  options={['DELTA.Wallet', 'Tinkoff', 'Sber', 'Other'].map((item) => ({
                    value: item,
                    label: paySystemMapping[item as keyof typeof paySystemMapping],
                  }))}
                  error={false}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label='Платежный метод' className={styles.form_item}>
                <CustomSelect
                  id={'pay-method-filter'}
                  className={styles.net_select}
                  value={logic.payMethodFilter ?? 'Все'}
                  onChange={(value) => emptyStringToUndefined(value, logic.setPayMethodFilter)}
                  emptyOption={{ value: '', label: 'Все' }}
                  options={['SYSTEM', 'UNKNOWN', 'P2P', 'MANUAL', 'CASH'].map((item) => ({ value: item, label: item }))}
                  error={false}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <div className={styles.date_pickers}>
                <FormItem label='Создан с' className={styles.form_item}>
                  <DatePicker
                    showTime={{ format: 'HH:mm' }}
                    format='YYYY-MM-DD HH:mm'
                    value={logic.createdFromFilter}
                    onChange={(pickedDate) => logic.setCreatedFromFilter(pickedDate)}
                    placeholder='Выберите дату'
                  />
                </FormItem>
                <FormItem label='Создан по' className={styles.form_item}>
                  <DatePicker
                    showTime={{ format: 'HH:mm' }}
                    format='YYYY-MM-DD HH:mm'
                    value={logic.createdToFilter}
                    onChange={(pickedDate) => logic.setCreatedToFilter(pickedDate)}
                    placeholder='Выберите дату'
                  />
                </FormItem>
              </div>
            </Col>
          </Row>

          <Row gutter={16}></Row>
        </Form>
      )}

      <div className={styles.filters_btns}>
        <Button onClick={logic.applyFilters}>Применить</Button>
        <Button onClick={logic.resetFilters}>Отменить</Button>
      </div>
    </Card>
  );
}
