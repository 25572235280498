import { Card, Col, DatePicker, Form, Row, Skeleton, Spin } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';
import { cn } from 'utils/classnames';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import { useUserActionsLogic } from '../../model/useUserActionsLogic';

import styles from './userActions.module.scss';

export function UserActions() {
  const logic = useUserActionsLogic();

  return (
    <Card>
      <Form layout={'vertical'}>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label={
                logic.userData?.data?.blockedUntil
                  ? `Заблокирован до ${transformDateToUserFriendly(logic.userData?.data?.blockedUntil)}`
                  : 'Блокировка аккаунта (смена статуса для профиля)'
              }
            >
              {logic.isUserDataLoading ? (
                <Skeleton.Button active style={{ width: '200px' }} />
              ) : (
                <Button
                  className={styles.block}
                  onClick={logic.userData?.data?.blockedUntil ? logic.handleUnblockUser : logic.openBlockUserModal}
                >
                  {logic.userData?.data?.blockedUntil ? 'Разблокировать' : 'Заблокировать'}
                </Button>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem label='Сброс 2FA (отключение 2FA)'>
              {logic.isUser2FADataLoading ? (
                <Skeleton.Button active style={{ width: '200px' }} />
              ) : (
                <Button className={styles.reset} disabled={logic.user2FAData?.data.isEnabled === false} onClick={logic.handleRemoveUser2FA}>
                  Сбросить
                </Button>
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>

      <CustomModal
        width={560}
        className={styles.modal}
        isOpen={logic.isBlockUserModalOpen}
        onClose={() => logic.setIsBlockUserModalOpen(false)}
        centered
      >
        <ModalTitle>
          <span>Заблокировать до</span>
        </ModalTitle>

        <form className={styles.form} onSubmit={logic.handleBlockUser}>
          <DatePicker
            disabledDate={logic.disabledDate}
            className={styles.data_picker}
            value={logic.blockedUntil}
            onChange={(pickedDate) => logic.setBlockedUntil(pickedDate)}
          />

          <Button className={cn(styles.confirm_btn, logic.blockedUntil && styles.active_btn)}>
            {logic.blockUserResponse.isLoading ? <Spin size='small' /> : 'Подтвердить'}
          </Button>
        </form>
      </CustomModal>
    </Card>
  );
}
