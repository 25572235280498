import { Dayjs } from 'dayjs';
import { TPanelType } from 'shared/types/Types/TPanelType';

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import {
  IChangeUserRequest,
  IUser2FARequest,
  IUser2FAResponse,
  IUserRequest,
  IUserResponse,
  IWallet,
  IWalletsRequest,
  IWalletsResponse,
  TChangeBalanceAction,
} from '../model/types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery,
  tagTypes: ['User', 'UserWallets', 'User2FAData'],
  endpoints: (builder) => ({
    getUser: builder.query<IUserResponse, IUserRequest>({
      providesTags: ['User'],
      query: (params) => {
        return {
          url: `/${params.panelType}/users/${params.userId}`,
        };
      },
    }),
    changeUser: builder.mutation<IUserResponse, IChangeUserRequest>({
      query: ({ panelType, userId, body }) => {
        return {
          url: `/${panelType}/users/${userId}`,
          method: 'PUT',
          body,
        };
      },
      // invalidatesTags: (result, error) => (error ? [] : ['User']),
    }),
    getUserBalance: builder.query<{ data: { balance: string } }, { panelType: TPanelType; userId: string }>({
      query: (params) => {
        return {
          url: `/${params.panelType}/users/${params.userId}/balance`,
        };
      },
    }),
    getUserWallets: builder.query<IWalletsResponse, IWalletsRequest>({
      providesTags: ['UserWallets'],
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[walletId]': params.walletId,
          'filter[userId]': params.userId,
          'filter[currencyCode]': params.currencyCode,
          'filter[createdFrom]': params.createdFrom,
          'filter[createdTo]': params.createdTo,
          'filter[hasBalance]': params.hasBalance,
          'filter[isEnabled]': params.isEnabled,
        };

        return {
          url: `/${params.panelType}/wallets/`,
          params: updatedParams,
        };
      },
    }),

    onOffWallet: builder.mutation<IWallet, { panelType: string; walletNumber: string; body: { isEnabled: boolean } }>({
      query: ({ panelType, walletNumber, body }) => {
        return {
          url: `/${panelType}/wallets/${walletNumber}`,
          method: 'PUT',
          body,
        };
      },
      // the function allows make an invalidate only after success
      invalidatesTags: (result, error) => (error ? [] : ['UserWallets']),
    }),

    changeBalanceWallet: builder.mutation<
      IWallet,
      { panelType: string; walletNumber: string; action: TChangeBalanceAction; body: { amount: string } }
    >({
      query: ({ panelType, walletNumber, action, body }) => {
        return {
          url: `/${panelType}/wallets/${walletNumber}/${action}`,
          method: 'PUT',
          body,
        };
      },
      // the function allows make an invalidate only after success
      invalidatesTags: (result, error) => (error ? [] : ['UserWallets']),
    }),
    blockUser: builder.mutation<unknown, { panelType: string; userId: string; body: { blockedUntil: Dayjs | null | undefined } }>({
      query: ({ panelType, userId, body }) => ({
        url: `/${panelType}/users/${userId}`,
        method: 'PUT',
        body,
      }),
    }),
    confirmKYC: builder.mutation<IUserResponse, unknown>({
      query: ({ panelType, userId }) => ({
        url: `/${panelType}/users/${userId}/kyc`,
        method: 'PUT',
      }),
    }),
    getKYCPassport: builder.query<string, unknown>({
      query: ({ panelType, userId }) => ({
        url: `/${panelType}/users/${userId}/kyc/document`,
        responseHandler: async (response) => URL.createObjectURL(await response.blob()),
      }),
    }),
    getKYCPhoto: builder.query<string, unknown>({
      query: ({ panelType, userId }) => ({
        url: `/${panelType}/users/${userId}/kyc/photo`,
        responseHandler: async (response) => URL.createObjectURL(await response.blob()),
      }),
    }),
    getUser2FA: builder.query<IUser2FAResponse, IUser2FARequest>({
      providesTags: ['User2FAData'],
      query: ({ panelType, userId }) => ({
        url: `/${panelType}/users/${userId}/otp`,
      }),
    }),
    removeUser2FA: builder.mutation<unknown, { panelType: string; userId: string }>({
      query: ({ panelType, userId }) => ({
        url: `/${panelType}/users/${userId}/otp`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['User2FAData']),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserBalanceQuery,
  useChangeUserMutation,
  useGetUserWalletsQuery,
  useBlockUserMutation,
  useRemoveUser2FAMutation,
  useChangeBalanceWalletMutation,
  useGetUser2FAQuery,
  useConfirmKYCMutation,
  useOnOffWalletMutation,
} = userApi;
