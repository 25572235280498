import { Card, Checkbox, Col, Form, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { CurrencyCodeSelector } from 'features/CurrencyCodeSelector';
import { emptyStringToUndefined } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';
import { NumericInput } from 'shared/ui/NumericInput/NumericInput';

import { useFiltersLogic } from '../../model/useFiltersLogic';

import styles from './filters.module.scss';

export function Filters() {
  const logic = useFiltersLogic();

  return (
    <Card title='Фильтр'>
      <Form layout={'vertical'}>
        <Row gutter={16}>
          <Col span={8}>
            <FormItem label='ID' className={styles.form_item}>
              <NumericInput value={logic.rateIdFilter} onChange={(value) => emptyStringToUndefined(value, logic.setRateIdFilter)} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Из валюты' className={styles.form_item}>
              <CurrencyCodeSelector
                value={logic.currencyCodeFromFilter ?? 'Все'}
                onChange={(value) => emptyStringToUndefined(value, logic.setCurrencyCodeFromFilter)}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='В валюту' className={styles.form_item}>
              <CurrencyCodeSelector
                value={logic.currencyCodeToFilter ?? 'Все'}
                onChange={(value) => emptyStringToUndefined(value, logic.setCurrencyCodeToFilter)}
              />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8} className={styles.checkbox}>
            <Checkbox checked={logic.showManualFilter} onChange={(e) => logic.setShowManualFilter(e.target.checked)}>
              Отображать только фиксированные курсы
            </Checkbox>
          </Col>
          <Col span={8} className={styles.checkbox}>
            <Checkbox checked={logic.showEnableFilter} onChange={(e) => logic.setShowEnableFilter(e.target.checked)}>
              Отображать всe включенные
            </Checkbox>
          </Col>
        </Row>
      </Form>

      <div className={styles.filters_btns}>
        <Button onClick={logic.applyFilters}>Применить</Button>
        <Button onClick={logic.resetFilters}>Отменить</Button>
      </div>
    </Card>
  );
}
