export const MAX_AMOUNT_LENGTH = 16;

export const round = (value: string, fraction: number = 2) => parseFloat(value).toFixed(fraction);

export const emptyStringToUndefined = (value: string, callback: (value: string | undefined) => void) =>
  value?.length === 0 ? callback(undefined) : callback(value);

export const emptyStringValueToUndefined = (value: string): string | undefined => {
  return value?.length === 0 ? undefined : value;
};

export const operationTypeMapping = {
  deposit: 'Внесение',
  withdrawal: 'Вывод',
  transfer: 'Перевод',
  exchange: 'Обмен',
  crypto: 'Крипто',
  unknown: 'Неизвестный',
};

export const transactionTypeMapping = {
  income: 'Пополнение',
  outcome: 'Списание',
};

export const transactionStatusMapping = {
  create: 'Создана',
  cancel: 'Отменена',
  success: 'Успешно',
  fail: 'Неудачно',
  confirm: 'Подтверждена',
  payed: 'Оплачено',
  timeout: 'Таймаут',
  decline: 'Отклонено',
};

export const paySystemMapping = {
  Sber: 'СБЕР',
  Tinkoff: 'Тинькофф',
  Other: 'СБП',
  'DELTA.Wallet': 'Delta wallet',
};
