import { type FC } from 'react';
import { Button } from 'shared/ui/Button/Button';
import Input from 'shared/ui/Input/Input';
import InputPassword from 'shared/ui/InputPassword/InputPassword';

import fingerDots from '../../../../assets/icons/fingerDots.svg';
import useLoginFormLogic from '../../model/useLoginFormLogic';

import styles from './loginForm.module.scss';

interface Props {}

const LoginForm: FC<Props> = () => {
  const { loginForm } = useLoginFormLogic();

  return (
    <form onSubmit={loginForm.handleSubmit}>
      <div className={styles.title}>
        <img src={fingerDots} alt='finger dots' />
        <h2>Вход</h2>
      </div>
      <Input
        type='text'
        id='login'
        name='login'
        placeholder='Логин'
        onChange={loginForm.handleChange}
        onBlur={loginForm.handleBlur}
        value={loginForm.values.login}
        error={loginForm.touched.login && loginForm.errors.login}
      />
      <InputPassword
        id='password'
        name='password'
        placeholder='Пароль'
        onChange={loginForm.handleChange}
        onBlur={loginForm.handleBlur}
        value={loginForm.values.password}
        error={loginForm.touched.password && loginForm.errors.password}
      />
      <Button className={styles.login_btn}>Войти</Button>
    </form>
  );
};

export default LoginForm;
