import { Col, Form, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { Button } from 'shared/ui/Button/Button';
import { InputEditable } from 'shared/ui/InputEditable/InputEditable';

import { useChangeUserEmail } from '../model/useChangeUserEmailLogic';

import styles from './changeUserEmail.module.scss';

export function ChangeUserEmail() {
  const { data, newEmail, confirmChanges, setNewEmail, handleResendEmail, handleConfirmEmail } = useChangeUserEmail();

  return (
    <Form layout='vertical' className={styles.form}>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem label='Email' className={styles.form_item}>
            <InputEditable
              name='email'
              placeholder='Email'
              value={newEmail}
              onConfirm={confirmChanges}
              onChange={(e) => setNewEmail(e.target.value)}
              onCancel={() => setNewEmail(data?.data?.email ?? '')}
            />
          </FormItem>
        </Col>
        <Col span={16}>
          <div className={styles.operate_btns}>
            <Button onClick={handleResendEmail}>Отправить заново</Button>
            <Button onClick={handleConfirmEmail}>Отправить подтверждение</Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
