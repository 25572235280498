import { Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { Button } from 'shared/ui/Button/Button';
import InputPassword from 'shared/ui/InputPassword/InputPassword';
import { cn } from 'utils/classnames';

import { useChangeUserPasswordLogic } from '../model/useChangeUserPasswordLogic';

import styles from './changeUserPassword.module.scss';

export function ChangeUserPassword() {
  const { confirmChanges, newPassword, handleChangePassword } = useChangeUserPasswordLogic();

  return (
    <Form onFinish={confirmChanges} layout='vertical' className={styles.form}>
      <FormItem label='Пароль' className={styles.form_item}>
        <InputPassword name='password' placeholder='Введите пароль' value={newPassword} onChange={handleChangePassword} />
      </FormItem>
      <div className={styles.button_container}>
        <Button className={cn(styles.change_password_btn, newPassword.length > 7 && styles.active_btn)}>Изменить пароль</Button>
      </div>
    </Form>
  );
}
