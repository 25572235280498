import { Image } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';
import { useAppMessage } from 'utils/messageContext';
import { userApi } from 'widgets/User';

// import styles from './getKYCPassportBtn.module.scss';

export function GetKYCPassportBtn() {
  const params = useParams();
  const location = useLocation();
  const { showError } = useAppMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [getKYCPassport, getKYCPassportResponse] = userApi.useLazyGetKYCPassportQuery();
  const { data } = userApi.useGetUserQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });

  useEffect(() => {
    if (getKYCPassportResponse.isSuccess) setIsModalOpen(true);
    if (getKYCPassportResponse.isError) showError({ data: { message: 'Изображение не пришло' }, status: 500 });
  }, [getKYCPassportResponse]);

  return (
    <>
      <Button
        onClick={() =>
          getKYCPassport({
            panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
            userId: params.id ?? 'frontend error',
          })
        }
      >
        Посмотреть паспорт
      </Button>

      <CustomModal width={560} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} centered getContainer={document.body}>
        <ModalTitle>
          <span>Паспорт {data?.data?.surname}</span>
        </ModalTitle>

        <br />
        <Image src={getKYCPassportResponse.data} alt='document photo' />
      </CustomModal>
    </>
  );
}
