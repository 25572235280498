import styles from './burger.module.scss';

function Burger() {
  return (
    <div className={styles.burger}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default Burger;
