import { DataType } from './types';
import { TableProps } from 'antd';
import { useLazyGetTransactionsCountQuery, useLazyGetTransactionsQuery } from 'entities/Transactions';
import { useState } from 'react';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;

export function usePayHistoryLogic() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);

  const [getTransactions, getTransactionsResponse] = useLazyGetTransactionsQuery();
  const [getTransactionsCount, getTransactionsCountResponse] = useLazyGetTransactionsCountQuery();

  const changeCurrentPage: OnChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current ?? 1);
  };

  return {
    data: getTransactionsResponse.data?.list,
    isLoading: getTransactionsResponse.isLoading,
    currentPage,
    changeCurrentPage,
    perPage,
    setPerPage,
    setCurrentPage,
    transactionsCount: getTransactionsCountResponse.data?.total,
    getTransactions,
    getTransactionsResponse,
    getTransactionsCount,
    getTransactionsCountResponse,
  };
}

export type TPayHistoryLogic = ReturnType<typeof usePayHistoryLogic>;
