import Table, { ColumnsType } from 'antd/es/table';
import { createContext } from 'react';
import { round } from 'shared/constants';

import { DataType } from '../../model/types';
import { TPayHistoryLogic, usePayHistoryLogic } from '../../model/usePayHistoryLogic';
import { Filters } from '../Filters/ui/Filters';

import styles from './payHistory.module.scss';

const columns: ColumnsType<DataType> = [
  {
    title: 'UID',
    dataIndex: 'operationId',
    key: 'operationId',
  },
  {
    title: 'Тип транзакции',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Номер счета',
    dataIndex: 'source',
    key: 'account number',
  },
  {
    title: 'Валюта',
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: 'Баланс',
    dataIndex: 'amount',
    key: 'amount',
    render: (balance) => `${round(balance)}`,
  },
];

export const PayHistoryContext = createContext<TPayHistoryLogic | null>(null);

export function PayHistory() {
  const logic = usePayHistoryLogic();

  return (
    <PayHistoryContext.Provider value={logic}>
      <div className={styles.container}>
        <Filters />

        <Table
          rowKey={(row) => row.operationId}
          columns={columns}
          dataSource={logic.data?.map((transaction) => {
            return {
              operationId: transaction.transactionId,
              type: transaction.transactionType,
              source: transaction.walletId,
              before: transaction.amount,
              after: transaction.amount,
              currency: transaction.currencyCode,
              amount: transaction.amount,
            };
          })}
          loading={logic.isLoading}
          onChange={logic.changeCurrentPage}
          scroll={{ x: 'scroll' }}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: true,
            current: logic.currentPage,
            pageSize: logic.perPage,
            onShowSizeChange: (currentPage, amount) => {
              logic.setCurrentPage(currentPage);
              logic.setPerPage(amount);
            },
            total: logic.transactionsCount,
            showTotal: (value) => `Всего финансовых операций: ${value}`,
            locale: { items_per_page: 'на стр.' },
          }}
        />
      </div>
    </PayHistoryContext.Provider>
  );
}
