import { useContext, useEffect, useState } from 'react';

import { RatesContext } from '../ui/RatesTable/RatesTable';

export function useFiltersLogic() {
  const context = useContext(RatesContext);

  const [rateIdFilter, setRateIdFilter] = useState<string | undefined>();
  const [currencyCodeFromFilter, setCurrencyCodeFromFilter] = useState<string | undefined>();
  const [currencyCodeToFilter, setCurrencyCodeToFilter] = useState<string | undefined>();
  const [showManualFilter, setShowManualFilter] = useState<boolean | undefined>();
  const [showEnableFilter, setShowEnableFilter] = useState<boolean | undefined>();

  const resetFilters = () => {
    setRateIdFilter(undefined);
    setCurrencyCodeFromFilter(undefined);
    setCurrencyCodeToFilter(undefined);
    setShowManualFilter(undefined);
    setShowEnableFilter(undefined);

    getDataWithoutFilters();
  };

  const applyFilters = () => {
    context?.getRates({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      page: context.currentPage,
      perPage: context.perPage,
      rateId: rateIdFilter !== undefined ? +rateIdFilter : undefined,
      currencyCodeFrom: currencyCodeFromFilter,
      currencyCodeTo: currencyCodeToFilter,
      isManual: showManualFilter,
      isEnabled: showEnableFilter,
    });
    context?.getRatesCount({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      rateId: rateIdFilter !== undefined ? +rateIdFilter : undefined,
      currencyCodeFrom: currencyCodeFromFilter,
      currencyCodeTo: currencyCodeToFilter,
      isManual: showManualFilter,
      isEnabled: showEnableFilter,
    });
  };

  function getDataWithoutFilters() {
    context?.getRates({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      page: context.currentPage,
      perPage: context.perPage,
    });
    context?.getRatesCount({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    });
  }

  useEffect(() => {
    getDataWithoutFilters();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [context?.currentPage]);

  return {
    rateIdFilter,
    setRateIdFilter,
    currencyCodeFromFilter,
    setCurrencyCodeFromFilter,
    currencyCodeToFilter,
    setCurrencyCodeToFilter,
    showManualFilter,
    setShowManualFilter,
    showEnableFilter,
    setShowEnableFilter,
    applyFilters,
    resetFilters,
  };
}
