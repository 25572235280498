import exclamationCircled from '../../../assets/icons/exclamation-circled-red.svg';

import styles from './error.module.scss';

function Error({ error }: { error: React.ReactNode | string }) {
  return (
    <div className={styles.error}>
      <img src={exclamationCircled} alt='exclamation circled' /> {error}
    </div>
  );
}

export default Error;
