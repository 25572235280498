import { PaymentCodeCourierDescription } from './PaymentCodeCourierDescription';
import { PaymentCodeDescription } from './PaymentCodeDescription';
import { PaymentCodeOfficeDescription } from './PaymentCodeOfficeDescription';
import { Col, Form, Popover, Row } from 'antd';
import Card from 'antd/es/card/Card';
import FormItem from 'antd/es/form/FormItem';
import { usePutTransactionDataMutation } from 'entities/Transactions';
import { useLocation } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { Button } from 'shared/ui/Button/Button';
import { useAppMessage } from 'utils/messageContext';

import { TUserPersonalData } from '../../model/useUserPersonalDataLogic';

import styles from './paymentCodes.module.scss';

export function PaymentCodes({ logic }: { logic: TUserPersonalData }) {
  const location = useLocation();
  const { showError, showSuccess } = useAppMessage();

  const [putTransaction] = usePutTransactionDataMutation();

  const cancelPayment = async (transactionId: string) => {
    try {
      await putTransaction({
        body: { status: 'decline' ?? 'frontend error' },
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        transactionId: transactionId ?? 'frontend error',
      });

      showSuccess('Платеж отменен');

      const newData: typeof logic.userData = JSON.parse(JSON.stringify(logic.userData));
      newData?.paymentCodes.forEach((code) => {
        if (code.transactionId === transactionId) {
          code.status = 'decline';
        }
      });

      logic.setUserData(newData);
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  const confirmPayment = async (transactionId: string) => {
    try {
      await putTransaction({
        body: { status: 'confirm' ?? 'frontend error' },
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        transactionId: transactionId ?? 'frontend error',
      });

      showSuccess('Платеж подтвержден');

      const newData: typeof logic.userData = JSON.parse(JSON.stringify(logic.userData));
      newData?.paymentCodes.forEach((code) => {
        if (code.transactionId === transactionId) {
          code.status = 'confirm';
        }
      });

      logic.setUserData(newData);
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  return (
    <Card className={styles.contacts_form}>
      <Form layout='vertical' className={styles.form}>
        <FormItem label='Платежные коды'>
          <Row gutter={16} className={styles.list}>
            {logic.userData?.paymentCodes?.map((code) => {
              return (
                <Col key={code.paymentCode} className={styles.payment_code}>
                  <Popover
                    trigger='click'
                    destroyTooltipOnHide
                    content={
                      <>
                        <PaymentCodeDescription codeInfo={code} />
                        {code.office ? <PaymentCodeOfficeDescription codeInfo={code} /> : <PaymentCodeCourierDescription codeInfo={code} />}

                        {code.status === 'create' && (
                          <>
                            <Button onClick={() => confirmPayment(code.transactionId)}>Подтвердить платеж</Button>
                            &nbsp;
                            <Button onClick={() => cancelPayment(code.transactionId)}>Отменить платеж</Button>
                          </>
                        )}
                      </>
                    }
                  >
                    <Button>{code.paymentCode}</Button>
                  </Popover>
                </Col>
              );
            })}
          </Row>
        </FormItem>
      </Form>
    </Card>
  );
}
