import { Checkbox, Flex, Skeleton, Spin, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { round } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';
import { CurrencyInput } from 'shared/ui/CurrencyInput';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';
import { IWallet } from 'widgets/User';

import fingerInCircle from '../../../../assets/icons/fingerInCircle.svg';
import { useUserAccountsLogic } from '../../model/useUserAccountsLogic';

import styles from './userAccounts.module.scss';

export function UserAccounts() {
  const logic = useUserAccountsLogic();

  const columns: ColumnsType<IWallet> = [
    {
      title: 'Номер счета',
      dataIndex: 'walletNumber',
      key: 'walletNumber',
    },
    {
      title: 'Баланс',
      dataIndex: 'balance',
      key: 'balance',
      render: (balance) => `${round(balance)}`,
    },
    {
      title: 'Включен',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      render: (isEnabled, record) => <Switch checked={isEnabled} onClick={() => logic.handleIsEnableSwitch(isEnabled, record)} />,
    },
    {
      title: 'Действия',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
            <Button onClick={() => logic.handleEditBalance(record, 'accrual')}>Пополнить</Button>
            &nbsp;
            <Button onClick={() => logic.handleEditBalance(record, 'write-of')}>Списать</Button>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.container}>
      {logic.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Checkbox className={styles.checkbox} checked={logic.hasBalance} onChange={logic.showUsersWithBalance}>
            Отображать профили с балансом
          </Checkbox>

          <Table
            rowKey={(row) => row.walletNumber}
            className={styles.table}
            pagination={false}
            columns={columns}
            dataSource={logic.data?.list}
            loading={logic.isLoading || logic.isFetching}
            scroll={{ x: 'scroll' }}
          />
        </>
      )}

      <CustomModal
        width={560}
        className={styles.modal}
        isOpen={Boolean(logic.editableAccountData)}
        onClose={() => logic.setEditableAccountData(undefined)}
        centered
      >
        <ModalTitle>
          <img src={fingerInCircle} alt='finger in circle' />
          <span>{`${logic.balanceAction === 'accrual' ? 'Пополнение' : 'Списание с'} кошелька`}</span>
        </ModalTitle>
        <div>Номер: {`${logic.editableAccountData?.walletNumber}`}</div>
        <div>Валюта: {`${logic.editableAccountData?.currencyCode}`}</div>

        <form onSubmit={logic.submitBalanceChange}>
          <Flex align='center' gap={5}>
            <div style={{ whiteSpace: 'nowrap' }}>На сумму:</div>
            <CurrencyInput
              autoFocus
              name='amount'
              id='user-balance'
              value={logic.newBalance}
              onChange={(e) => logic.setNewBalance(e.target.value)}
            />
          </Flex>

          <Flex justify='flex-end' gap={10} className={styles.control_btns}>
            <Button className={styles.confirm_btn}>{logic.changeBalanceResponse.isLoading ? <Spin size='small' /> : 'Сохранить'}</Button>
            <Button
              className={styles.confirm_btn}
              onClick={(e) => {
                e.preventDefault();
                logic.setNewBalance('');
                logic.setEditableAccountData(undefined);
              }}
            >
              Отменить
            </Button>
          </Flex>
        </form>
      </CustomModal>
    </div>
  );
}
