import { Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useContext, useEffect, useState } from 'react';

import { emptyStringValueToUndefined } from '../../../shared/constants';
import { UsersContext } from '../ui/Users/Users';

export function useFiltersLogic() {
  const context = useContext(UsersContext);
  const [form] = Form.useForm();

  const createdFromFilter = Form.useWatch('createdFrom', form);
  const createdToFilter = Form.useWatch('createdTo', form);
  const profileIdFilter = Form.useWatch('profileId', form);
  const userIdFilter = Form.useWatch('userId', form);
  const emailFilter = Form.useWatch('email', form);

  const [hasBalance, setHasBalance] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const resetFilters = () => {
    setHasBalance(false);
    form.resetFields();
    getDataWithoutFilters();
  };

  const applyFilters = () => {
    context?.getUsers({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      createdAt: undefined,
      createdFrom: createdFromFilter?.toISOString(),
      createdTo: createdToFilter?.toISOString(),
      email: emptyStringValueToUndefined(emailFilter),
      hasBalance: hasBalance,
      page: context.currentPage,
      perPage: context.perPage,
      profileId: profileIdFilter,
      userId: userIdFilter,
    });
    context?.getUsersCount({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      createdAt: undefined,
      createdFrom: createdFromFilter?.toISOString(),
      createdTo: createdToFilter?.toISOString(),
      email: emptyStringValueToUndefined(emailFilter),
      hasBalance: hasBalance,
      profileId: profileIdFilter,
      userId: userIdFilter,
    });
  };

  const showUsersWithBalance = (e: CheckboxChangeEvent) => {
    setHasBalance(e.target.checked);
  };

  function getDataWithoutFilters() {
    context?.getUsers({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      page: context.currentPage,
      perPage: context.perPage,
    });
    context?.getUsersCount({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    });
  }

  const handleEmailFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isNoSpace: boolean = !/\s/.test(e.target.value);
    const isValidMinLength: boolean = e.target.value.trim().length >= 3;
    const isFirstSymbolIsLetter: boolean = /^[\p{L}\d]/u.test(e.target.value);

    if (!e.target.value) {
      return setHasError(false);
    }

    if (isNoSpace && isValidMinLength && isFirstSymbolIsLetter) {
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    getDataWithoutFilters();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [context?.currentPage]);

  return {
    flow: {
      showUsersWithBalance,
      applyFilters,
      resetFilters,
      handleEmailFilterChange,
    },
    state: {
      form,
      hasError,
      userIdFilter,
      emailFilter,
      profileIdFilter,
      createdFromFilter,
      createdToFilter,
      hasBalance,
    },
  };
}
