import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';
import { IWallet, TChangeBalanceAction, useChangeBalanceWalletMutation, useGetUserWalletsQuery, useOnOffWalletMutation } from 'widgets/User';

export function useUserAccountsLogic() {
  const location = useLocation();
  const params = useParams();
  const [editableAccountData, setEditableAccountData] = useState<IWallet | undefined>();
  const [newBalance, setNewBalance] = useState('');
  const [balanceAction, setBalanceAction] = useState<TChangeBalanceAction>(null);
  const [hasBalance, setHasBalance] = useState(false);
  const { showError, showSuccess } = useAppMessage();

  const { data, isLoading, isFetching, isError, error } = useGetUserWalletsQuery({
    panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
    hasBalance,
  });

  const [changeBalance, changeBalanceResponse] = useChangeBalanceWalletMutation();
  const [onOffWallet, onOffWalletResponse] = useOnOffWalletMutation();

  const submitBalanceChange = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!newBalance) return showError({ data: { message: 'Введите сумму' }, status: 400 });

    try {
      await changeBalance({
        action: balanceAction,
        body: { amount: newBalance },
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        walletNumber: data?.list.find((wallet) => wallet.walletNumber === editableAccountData?.walletNumber)?.walletId ?? 'frontend error',
      });

      showSuccess('Баланс успешно изменен');
      setNewBalance('');
      setEditableAccountData(undefined);
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  const handleEditBalance = (record: IWallet, action: typeof balanceAction) => {
    setEditableAccountData(record);
    setBalanceAction(action);
  };

  const showUsersWithBalance = () => {
    setHasBalance((prev) => !prev);
  };

  const handleIsEnableSwitch = async (isEnabled: boolean, record: IWallet) => {
    try {
      await onOffWallet({
        walletNumber: record.walletId,
        body: { isEnabled: !isEnabled },
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      });

      showSuccess('Успех');
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  useEffect(() => {
    if (isError) showError(error as IResponseError);
  }, [isError, error]);

  useEffect(() => {
    if (changeBalanceResponse.isError || onOffWalletResponse.isError)
      showError((changeBalanceResponse.error || onOffWalletResponse.error) as IResponseError);
  }, [changeBalanceResponse.isError, changeBalanceResponse.error, onOffWalletResponse.isError]);

  return {
    setEditableAccountData,
    isLoading,
    isFetching,
    data,
    editableAccountData,
    submitBalanceChange,
    handleEditBalance,
    newBalance,
    setNewBalance,
    changeBalanceResponse,
    hasBalance,
    showUsersWithBalance,
    handleIsEnableSwitch,
    balanceAction,
  };
}
