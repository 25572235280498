import { ITransaction, useGetTransactionDataQuery, usePutTransactionDataMutation, useGetTransactionInfoQuery } from 'entities/Transactions';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { groupArrByColNumber } from 'utils/groupArrByColNumber';
import { isNotEmptyValue } from 'utils/isNotEmptyValue';
import { useAppMessage } from 'utils/messageContext';

export function useFinOperationLogic() {
  const { id } = useParams();
  const { showError, showSuccess } = useAppMessage();
  const [oldComment, setOldComment] = useState('');
  const [newComment, setNewComment] = useState('');
  const [transactionStatus, setTransactionStatus] = useState<string | null>(null);
  const [transactionData, setTransactionData] = useState<ITransaction | undefined>();
  // const [transactionInfo, setTransactionInfo] = useState<ITransactionInfo | undefined>();

  const { data, isLoading, isFetching } = useGetTransactionDataQuery(
    {
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      transactionId: id ?? 'frontend error',
    },
    { refetchOnMountOrArgChange: true }
  );

  const doNotFetchOperationTypeArr = ['transfer', 'exchange', 'unknown'];
  const shouldFetchInfo = data && !doNotFetchOperationTypeArr.includes(data.data.operationType);
  const {
    data: info,
    isLoading: isInfoLoading,
    isFetching: isInfoFetching,
  } = useGetTransactionInfoQuery(
    {
      panelType: 'wallet',
      transactionId: id ?? 'frontend error',
    },
    { skip: !shouldFetchInfo, refetchOnMountOrArgChange: true }
  );

  const infoHiddenKeys = ['id', 'transactionId'];
  const transactionInfoArr = info?.data
    ? Object.entries(info.data).filter(([key, value]) => isNotEmptyValue(value) && !infoHiddenKeys.includes(key))
    : [];
  const transactionInfo = groupArrByColNumber(transactionInfoArr, 4);

  function openChangeStatusWindow() {
    setEditTransactionId(id ?? '');
  }

  const [putTransaction] = usePutTransactionDataMutation();

  async function changeTransactionComment() {
    try {
      putTransaction({
        body: { comment: newComment ?? 'frontend error' },
        panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        transactionId: id ?? 'frontend error',
      });

      showSuccess('Комментарий изменен');
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  const [editTransactionId, setEditTransactionId] = useState<ITransaction['transactionId'] | null>(null);

  async function changeTransactionStatus(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      putTransaction({
        body: { status: transactionStatus ?? 'frontend error' },
        panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        transactionId: editTransactionId ?? 'frontend error',
      });
      setEditTransactionId(null);

      showSuccess('Статус изменен');
      setTransactionData({ ...transactionData, status: transactionStatus } as ITransaction);
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  useEffect(() => {
    setNewComment(data?.data.comment ?? '');
    setOldComment(data?.data.comment ?? '');
  }, [data?.data.comment]);

  useEffect(() => {
    if (data?.data.status) setTransactionStatus(data?.data.status);
  }, [data?.data.status]);

  useEffect(() => {
    if (data?.data) setTransactionData(data.data);
  }, [data?.data]);

  return {
    data: transactionData,
    isLoading,
    isFetching,
    newComment,
    setNewComment,
    changeTransactionComment,
    openChangeStatusWindow,
    editTransactionId,
    setEditTransactionId,
    changeTransactionStatus,
    transactionStatus,
    setTransactionStatus,
    oldComment,
    info: transactionInfo,
    isInfoLoading,
    isInfoFetching,
  };
}
