import { baseQuery } from 'shared/api';

import { createApi } from '@reduxjs/toolkit/query/react';

import type { SignInRequest, AuthResponse } from '../model/types';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    signIn: builder.mutation<AuthResponse, SignInRequest>({
      query: (body) => ({
        url: '/signin',
        method: 'POST',
        body,
      }),
    }),
    signOut: builder.mutation<unknown, unknown>({
      query: () => ({
        url: '/signin',
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useSignInMutation, useSignOutMutation } = authApi;
