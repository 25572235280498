import { Skeleton } from 'antd';

import useUserPersonalDataLogic from '../../model/useUserPersonalDataLogic';
import { FirstSection } from '../FirstSection/FirstSection';
import { PaymentCodes } from '../PaymentCodes/PaymentCodes';
import { SecondSection } from '../SecondSection/SecondSection';
import { UserKYCInfo } from '../UserKYCInfo/UserKYCInfo';

export function UserPersonalData() {
  const logic = useUserPersonalDataLogic();

  return logic.isLoading ? (
    <Skeleton active />
  ) : (
    <>
      <FirstSection />
      <PaymentCodes logic={logic} />
      <UserKYCInfo logic={logic} />
      <SecondSection logic={logic} />
    </>
  );
}
