import { Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import { round } from 'shared/constants';
import { IUser } from 'widgets/User';

import { EyeOutlined } from '@ant-design/icons';

import styles from './walletsTooltip.module.scss';

export function WalletsTooltip({ walletsWithBalance, userId }: { walletsWithBalance: IUser['wallets']; userId: IUser['userId'] }) {
  return (
    <Tooltip
      placement='right'
      overlayStyle={{ maxWidth: '500px' }}
      overlayClassName={styles.tooltip}
      title={
        walletsWithBalance.length > 0
          ? walletsWithBalance.map((wallet) => (
              <NavLink
                key={wallet.walletNumber}
                className={styles.wallet}
                to={`${userId}/?tab=operations&wallet_number=${wallet.walletNumber.slice(4)}`}
              >
                {wallet.walletNumber} Balance: {round(wallet.balance)}
              </NavLink>
            ))
          : undefined
      }
    >
      {walletsWithBalance.length ?? '0'} {walletsWithBalance.length > 0 && <EyeOutlined />}
    </Tooltip>
  );
}
