import { Form, Switch } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { operationTypeMapping, round } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';
import { EditableCell } from 'shared/ui/EditableCell/EditableCell';

import { CloseCircleTwoTone, SaveTwoTone } from '@ant-design/icons';

import { CustomColumnType, IMarkupListItem } from '../../model/types';
import { useMarkupTableLogic } from '../../model/useMarkupTableLogic';

import styles from './markupTable.module.scss';

export function MarkupTable() {
  const logic = useMarkupTableLogic();

  const columns: CustomColumnType<IMarkupListItem>[] = [
    {
      title: 'Валютная пара',
      dataIndex: 'couple',
      key: 'couple',
      render: (_, record) => (
        <div>
          {record.currencyCodeFrom}-{record.currencyCodeTo}
        </div>
      ),
    },
    {
      title: 'Операция',
      dataIndex: 'operation',
      key: 'operation',
      // width: '110px',
      sorter: (a, b) => a.operation.localeCompare(b.operation),
      sortOrder: logic.sortedInfo.columnKey === 'operation' ? logic.sortedInfo.order : null,
      render: (value: keyof typeof operationTypeMapping) => operationTypeMapping[value],
    },
    {
      title: 'Наценка',
      dataIndex: 'markupValue',
      key: 'markupValue',
      width: '20%',
      editable: true,
      render: (value) => `${round(value)}`,
      sorter: (a, b) => +a.markupValue - +b.markupValue,
      sortOrder: logic.sortedInfo.columnKey === 'markupValue' ? logic.sortedInfo.order : null,
    },
    {
      title: 'Наценка (%)',
      dataIndex: 'markupPercent',
      key: 'markupPercent',
      width: '20%',
      editable: true,
      render: (value) => `${round(value)}%`,
      sorter: (a, b) => +a.markupPercent - +b.markupPercent,
      sortOrder: logic.sortedInfo.columnKey === 'markupPercent' ? logic.sortedInfo.order : null,
    },
    {
      title: 'Процент',
      dataIndex: 'isPercent',
      key: 'isPercent',
      editable: true,
      width: '110px',
      render: (isPercent) => <Switch checked={isPercent} disabled />,
    },
    {
      title: 'Включен',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      width: '110px',
      editable: true,
      render: (isPercent) => <Switch checked={isPercent} disabled />,
    },
    {
      title: 'Действия',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: '130px',
      render: (_, record) => {
        const editable = logic.isEditing(record);
        return editable ? (
          <div className={styles.control_btns_container}>
            <SaveTwoTone className={styles.control_btn} onClick={() => logic.save(record.markupId)} />
            <CloseCircleTwoTone className={styles.control_btn} onClick={logic.cancel} twoToneColor={'red'} />
          </div>
        ) : (
          <Button onClick={() => logic.edit(record)}>Изменить</Button>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record: IMarkupListItem) => ({
        record,
        inputType: col.dataIndex === 'isPercent' || col.dataIndex === 'isEnabled' ? 'checkbox' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: logic.isEditing(record),
      }),
    };
  });

  return (
    <Form form={logic.form} component={false}>
      <Table
        rowKey={(row) => row.markupId}
        columns={mergedColumns as ColumnsType<IMarkupListItem>}
        dataSource={logic.data}
        onChange={logic.handleChange}
        scroll={{ x: 'scroll' }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        pagination={{
          position: ['topRight', 'bottomRight'],
          showSizeChanger: true,
          current: logic.currentPage,
          pageSize: logic.perPage,
          onShowSizeChange: (currentPage, amount) => {
            logic.setCurrentPage(currentPage);
            logic.setPerPage(amount);
          },
          locale: { items_per_page: 'на стр.' },
        }}
        loading={logic.isLoading || logic.isFetching}
      />
    </Form>
  );
}
