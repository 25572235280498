import { Input } from 'antd';
import { useTransition } from 'react';

interface NumericInputProps {
  value: string | undefined;
  onChange: (value: string) => void;
}

export function NumericInput({ value, onChange }: NumericInputProps) {
  const [isPending, startTransition] = useTransition();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    startTransition(() => onChange(e.target.value));
  };

  return <Input type='number' value={value} onChange={handleChange} />;
}
