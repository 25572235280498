import { IRate } from './types';
import { InputRef, TableProps } from 'antd';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

import { useLazyGetRatesCountQuery, useLazyGetRatesQuery, usePutRateMutation } from '../api/ratesApi';

export type DataIndex = keyof IRate;

type OnChange = NonNullable<TableProps<IRate>['onChange']>;
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

export function useRatesTableLogic() {
  const location = useLocation();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [filteredData, setFilteredData] = useState<IRate[] | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const { showError, showSuccess } = useAppMessage();

  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const [getRates, getRatesResponse] = useLazyGetRatesQuery();
  const [getRatesCount, getRatesCountResponse] = useLazyGetRatesCountQuery();

  const [putRate] = usePutRateMutation();

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const handleIsEnableSwitch = async (isEnabled: boolean, record: IRate) => {
    try {
      await putRate({
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        rateId: record.rateId ?? 'frontend error',
        body: {
          isEnabled: !isEnabled,
        },
      }).unwrap();

      showSuccess('Успех');
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  const handleIsManualSwitch = async (isManual: boolean, record: IRate) => {
    if (+record.rateManual <= 0 && record.isManual === false)
      return showError({ status: 100, data: { message: 'Введите значение > 0.01' } } as IResponseError);

    try {
      putRate({
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        rateId: record.rateId ?? 'frontend error',
        body: {
          isManual: !isManual,
        },
      }).unwrap();

      showSuccess('Успех');
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current ?? 0);
    setSortedInfo(sorter as Sorts);
  };

  useEffect(() => {
    if (getRatesResponse.isError) showError(getRatesResponse.error as IResponseError);
  }, [getRatesResponse.isError, getRatesResponse.error]);

  useEffect(() => {
    if (getRatesResponse.isLoading === false && getRatesResponse.data) {
      setFilteredData(getRatesResponse.data.list);
    }
  }, [getRatesResponse.isLoading, getRatesResponse.isFetching]);

  return {
    searchInput,
    handleSearch,
    handleReset,
    filteredData,
    searchedColumn,
    searchText,
    isLoading: getRatesResponse.isLoading,
    isFetching: getRatesResponse.isFetching,
    handleIsEnableSwitch,
    handleIsManualSwitch,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    sortedInfo,
    handleChange,
    ratesCounter: getRatesCountResponse.data?.total,
    getRates,
    getRatesResponse,
    getRatesCount,
    getRatesCountResponse,
  };
}

export type TRatesTableLogic = ReturnType<typeof useRatesTableLogic>;
