import { Content } from 'antd/es/layout/layout';
import { NavLink } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';

import styles from './home.module.scss';

export function HomePage() {
  return (
    <Content className={styles.home}>
      <h2>Панель администратора</h2>
      <h3>
        Выберите проект
        <br />
        <div className={styles.btns}>
          <NavLink to='/wallet/users'>
            <Button>WALLET</Button>
          </NavLink>
          <NavLink to='#'>
            <Button>MERCHANT</Button>
          </NavLink>
        </div>
      </h3>
    </Content>
  );
}
