import { TabsProps } from 'antd';
import { UserAccounts } from 'features/UserAccounts';
import { UserActions } from 'features/UserActions';
import { NavLink } from 'react-router-dom';
import { PayHistory } from 'widgets/PayHistory';
import { UserPersonalData } from 'widgets/UserPersonalData';
import { WalletControlTabs } from 'widgets/WalletControlTabs';

const tabs: TabsProps['items'] = [
  {
    key: 'user',
    label: <NavLink to='?tab=user'>Данные пользователя</NavLink>,
    children: <UserPersonalData />,
  },
  {
    key: 'accounts',
    label: <NavLink to='?tab=accounts'>Счета</NavLink>,
    children: <UserAccounts />,
  },
  {
    key: 'operations',
    label: <NavLink to='?tab=operations'>Список операций</NavLink>,
    children: <PayHistory />,
  },
  {
    key: 'actions',
    label: <NavLink to='?tab=actions'>Действия</NavLink>,
    children: <UserActions />,
  },
];

export function User() {
  return <WalletControlTabs tabs={tabs} />;
}
