import { TPanelType } from 'shared/types/Types/TPanelType';

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import {
  IChangeTransactionRequest,
  ITransaction,
  ITransactionInfo,
  ITransactionRequest,
  ITransactionStatusesResponse,
  ITransactionsResponse,
} from '../model/types';

export const transactionsApi = createApi({
  reducerPath: 'transactionsApi',
  baseQuery,
  tagTypes: ['TransactionsData', 'TransactionData'],
  endpoints: (builder) => ({
    getTransactions: builder.query<ITransactionsResponse, ITransactionRequest>({
      providesTags: ['TransactionsData'],
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[transactionId]': params.transactionId,
          'filter[paymentCode]': params.paymentCode,
          'filter[userId]': params.userId,
          'filter[walletId]': params.walletId,
          'filter[currencyCode]': params.currencyCode,
          'filter[createdFrom]': params.createdFrom,
          'filter[createdTo]': params.createdTo,
          'filter[operationType]': params.operationType,
          'filter[transactionType]': params.transactionType,
          'filter[status]': params.status,
          'filter[isManual]': params.isManual,
          'filter[payMethod]': params.payMethod,
          'filter[paySystem]': params.paySystem,
        };

        return {
          url: `/${params.panelType}/transactions/`,
          params: updatedParams,
        };
      },
    }),
    getTransactionStatuses: builder.query<ITransactionStatusesResponse, { panelType: TPanelType }>({
      query: (params) => {
        return {
          url: `/${params.panelType}/transaction-statuses`,
        };
      },
    }),
    getTransactionTypes: builder.query<{ list: (string | undefined)[] }, { panelType: TPanelType }>({
      query: (params) => {
        return {
          url: `/${params.panelType}/transaction-types`,
        };
      },
    }),
    getTransactionData: builder.query<{ data: ITransaction }, { panelType: TPanelType; transactionId: ITransaction['transactionId'] }>({
      providesTags: ['TransactionData'],
      query: (params) => {
        return {
          url: `/${params.panelType}/transactions/${params.transactionId}`,
        };
      },
    }),
    getTransactionInfo: builder.query<{ data: ITransactionInfo }, { panelType: 'wallet'; transactionId: ITransaction['transactionId'] }>({
      query: (params) => {
        return {
          url: `/${params.panelType}/transactions/${params.transactionId}/info`,
        };
      },
    }),
    getTransactionsCount: builder.query<{ total: number }, ITransactionRequest>({
      query: (params) => {
        const updatedParams = {
          'filter[transactionId]': params.transactionId,
          'filter[userId]': params.userId,
          'filter[walletId]': params.walletId,
          'filter[currencyCode]': params.currencyCode,
          'filter[createdFrom]': params.createdFrom,
          'filter[createdTo]': params.createdTo,
          'filter[operationType]': params.operationType,
          'filter[transactionType]': params.transactionType,
          'filter[status]': params.status,
          'filter[isManual]': params.isManual,
          'filter[payMethod]': params.payMethod,
          'filter[paySystem]': params.paySystem,
        };

        return {
          url: `/${params.panelType}/transactions/count`,
          params: updatedParams,
        };
      },
    }),

    putTransactionData: builder.mutation<{ data: any }, IChangeTransactionRequest>({
      query: ({ panelType, transactionId, body }) => {
        return {
          url: `/${panelType}/transactions/${transactionId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['TransactionsData'],
    }),

    putTransactionCourier: builder.mutation<any, { body: { courierCallingAt: string }; transactionId: string }>({
      query: ({ transactionId, body }) => {
        return {
          url: `/wallet/transactions/${transactionId}/courier`,
          method: 'PUT',
          body,
        };
      },
      // invalidatesTags: ['TransactionsData'],
    }),
  }),
});

export const {
  useGetTransactionStatusesQuery,
  useGetTransactionsQuery,
  useLazyGetTransactionsQuery,
  useGetTransactionDataQuery,
  useLazyGetTransactionDataQuery,
  useGetTransactionInfoQuery,
  usePutTransactionDataMutation,
  useGetTransactionTypesQuery,
  useLazyGetTransactionsCountQuery,
  usePutTransactionCourierMutation,
} = transactionsApi;
