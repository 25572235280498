import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { cn } from 'utils/classnames';

import arrowDown from '../../../assets/icons/arrow-down.svg';
import Error from '../Error/Error';

import styles from './customSelect.module.scss';

interface ICustomSelectProps extends SelectProps {
  error: string | false | undefined;
  emptyOption?: { value: string; label: string };
}

function CustomSelect({ className, error, value, options, emptyOption, ...props }: ICustomSelectProps) {
  return (
    <>
      <Select
        status={error ? 'error' : ''}
        bordered={false}
        className={cn(styles.select, className)}
        value={value || null}
        suffixIcon={<img src={arrowDown} alt='arrow' />}
        popupClassName={styles.dropdown}
        virtual={false}
        {...props}
      >
        {emptyOption && <Select.Option value={emptyOption.value}>{emptyOption.label}</Select.Option>}
        {options?.map((option, index) => (
          <Select.Option key={String(index)} value={String(option.value)}>
            {String(option.label)}
          </Select.Option>
        ))}
      </Select>
      {error && <Error error={error} />}
    </>
  );
}

export default CustomSelect;
