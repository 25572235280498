import App from './app/App';
import { ConfigProvider } from 'antd';
import ru from 'antd/lib/locale/ru_RU';
import { store } from 'app/GlobalRedux/store';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <Router>
      <ConfigProvider locale={ru}>
        <App />
      </ConfigProvider>
    </Router>
  </Provider>
);
