import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { Button } from 'shared/ui/Button/Button';
import Checkbox from 'shared/ui/Checkbox/Checkbox';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import InputPassword from 'shared/ui/InputPassword/InputPassword';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';

import fingerInCircle from '../../../../assets/icons/fingerInCircle.svg';

import styles from './adminsTable.module.scss';

interface DataType {
  id: string;
  date: string;
  login: string;
  password: string;
  active: boolean;
}

const data: DataType[] = [
  {
    id: 'XXX-0000',
    date: '1985-08-25',
    login: 'firstAdmin',
    password: 'password',
    active: false,
  },
  {
    id: 'XXX-0001',
    date: '1985-08-25',
    login: 'secondAdmin',
    password: 'password',
    active: true,
  },
  {
    id: 'XXX-0002',
    date: '1985-08-25',
    login: 'thirdAdmin',
    password: 'password',
    active: false,
  },
  {
    id: 'XXX-0003',
    date: '1985-08-25',
    login: 'fourthAdmin',
    password: 'password',
    active: true,
  },
];

export function AdminsTable() {
  const [editPasswordAdminId, setEditPasswordAdminId] = useState<DataType['id'] | null>(null);

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Creation date',
      dataIndex: 'date',
      key: 'date',
      // render: (value: string, { currencyCode }) => `${value ?? ''} ${currencyCode}`,
    },
    {
      title: 'Login',
      dataIndex: 'login',
      key: 'login',
      // render: (value: string, { currencyCode }) => `${value ?? ''} ${currencyCode}`,
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
      render: (_, record) => (
        <Button className={styles.btn} onClick={() => setEditPasswordAdminId(record.id)}>
          Edit
        </Button>
      ),
    },
    {
      title: 'Is active',
      dataIndex: 'active',
      key: 'active',
      render: (value: boolean) => <Checkbox onChange={() => null} />,
    },
  ];

  return (
    <div className={styles.container}>
      <Table rowKey={(row) => row.id} className={styles.table} pagination={false} columns={columns} dataSource={data} loading={false} />

      <CustomModal
        width={560}
        className={styles.modal}
        isOpen={Boolean(editPasswordAdminId)}
        onClose={() => setEditPasswordAdminId(null)}
        centered
      >
        <ModalTitle>
          <img src={fingerInCircle} alt='finger in circle' />
          <span>Edit admin {editPasswordAdminId} password</span>
        </ModalTitle>
        <br />
        <form onSubmit={() => null}>
          <InputPassword placeholder='Password' />
          <InputPassword placeholder='Confirm password' />

          <Button className={styles.confirm_btn}>Confirm</Button>
        </form>
      </CustomModal>
    </div>
  );
}
