import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { ICurrencyRequest, ICurrencyResponse } from '../model/type';

export const currenciesApi = createApi({
  reducerPath: 'currenciesApi',
  baseQuery,
  endpoints: (builder) => ({
    getCurrencies: builder.query<ICurrencyResponse, ICurrencyRequest>({
      query: (params) => {
        return {
          url: `/${params.panelType}/currencies`,
        };
      },
    }),
  }),
});

export const { useGetCurrenciesQuery } = currenciesApi;
