import { baseQuery } from 'shared/api';

import { createApi } from '@reduxjs/toolkit/query/react';

export const timeApi = createApi({
  reducerPath: 'timeApi',
  baseQuery,
  endpoints: (builder) => ({
    getTime: builder.query<{ message: string }, unknown>({
      query: () => ({
        url: '/time',
      }),
    }),
  }),
});

export const { useGetTimeQuery } = timeApi;
