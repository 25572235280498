import { Col, Form, Row } from 'antd';
import Card from 'antd/es/card/Card';
import FormItem from 'antd/es/form/FormItem';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { Button } from 'shared/ui/Button/Button';
import Input from 'shared/ui/Input/Input';
import { useAppMessage } from 'utils/messageContext';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';
import { useConfirmKYCMutation } from 'widgets/User';

import { TUserPersonalData } from '../../model/useUserPersonalDataLogic';

import styles from './userKYCInfo.module.scss';

export function UserKYCInfo({ logic }: { logic: TUserPersonalData }) {
  const params = useParams();
  const { showError, showSuccess } = useAppMessage();

  const [confirmKYC] = useConfirmKYCMutation();

  const confirmKYCHandler = async () => {
    try {
      const response = await confirmKYC({
        panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        userId: params.id,
      }).unwrap();

      if (response.data) {
        showSuccess('Успех');

        const newData: NonNullable<typeof logic.userData> = JSON.parse(JSON.stringify(logic.userData));
        newData.kyc = response.data.kyc;

        logic.setUserData(newData);
      }
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  return (
    <Card className={styles.kyc_form}>
      <Form labelWrap layout='vertical' className={styles.form}>
        <Row gutter={16}>
          {logic.userData?.kyc && (
            <>
              <Col span={4}>
                <FormItem label='KYC ID' className={styles.form_item}>
                  <Input value={logic.userData.kyc.userKycId} readOnly disabled />
                </FormItem>
              </Col>

              <Col span={4}>
                <FormItem label='KYC Статус' className={styles.form_item}>
                  <Input name='status' value={logic.userData.kyc.status} readOnly disabled />
                </FormItem>
              </Col>

              <Col span={4}>
                <FormItem label='Создано' className={styles.form_item}>
                  <Input name='createdAt' value={transformDateToUserFriendly(logic.userData.kyc.createdAt)} readOnly disabled />
                </FormItem>
              </Col>

              <Col span={4}>
                <FormItem label='Обновлено' className={styles.form_item}>
                  <Input name='updatedAt' value={transformDateToUserFriendly(logic.userData.kyc.updatedAt)} readOnly disabled />
                </FormItem>
              </Col>
            </>
          )}

          <Col span={6}>
            <FormItem label='Ручное изменение' className={styles.form_item}>
              <Button onClick={confirmKYCHandler}>Подтвердить KYC</Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
