import { Col, Form, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { InputEditable } from 'shared/ui/InputEditable/InputEditable';

import { useChangeUserPhoneLogic } from '../model/useChangeUserPhoneLogic';

import styles from './changeUserPhone.module.scss';

export function ChangeUserPhone() {
  const { data, newPhone, confirmChanges, setNewPhone } = useChangeUserPhoneLogic();

  return (
    <Form layout='vertical'>
      <FormItem label='Номер телефона' className={styles.form_item}>
        <InputEditable
          name='phone'
          placeholder='Номер телефона'
          value={newPhone}
          onConfirm={confirmChanges}
          onChange={(e) => setNewPhone(e.target.value)}
          onCancel={() => setNewPhone(data?.data?.phone ?? '')}
        />
      </FormItem>
    </Form>
  );
}
