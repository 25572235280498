import { usePutPhoneMutation } from 'entities/Phones';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';
import { useGetUserQuery } from 'widgets/User';

export function useChangeUserPhoneLogic() {
  const params = useParams();
  const { showError, showSuccess } = useAppMessage();
  const [putPhone, putPhoneResponse] = usePutPhoneMutation();
  const [newPhone, setNewPhone] = useState('');

  const { data } = useGetUserQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });

  const confirmChanges = () => {
    if (newPhone === data?.data?.phone) return showError({ data: { message: 'Внесите изменения' }, status: 100 });
    putPhone({
      body: {
        phone: newPhone,
      },
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      phoneId: data?.data?.phoneId ?? 'frontend error',
    });
  };

  useEffect(() => {
    setNewPhone(data?.data?.phone ?? '');
  }, [data]);

  useEffect(() => {
    if (putPhoneResponse.isError) showError(putPhoneResponse.error as IResponseError);
  }, [putPhoneResponse.isError, putPhoneResponse.error]);

  useEffect(() => {
    if (putPhoneResponse.isSuccess) showSuccess('Номер изменен');
  }, [putPhoneResponse.isSuccess]);

  return {
    data,
    newPhone,
    confirmChanges,
    setNewPhone,
  };
}
