import { useState } from 'react';
import { IInputProps } from 'shared/types/Interfaces/IInputProps';
import { cn } from 'utils/classnames';

import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import Input from '../Input/Input';

import styles from './inputEditable.module.scss';

type TInputPasswordProps = Omit<IInputProps, 'type'> & { onConfirm: () => void; onCancel: () => void };

export function InputEditable({ id, name, placeholder, value, onChange, onConfirm, onCancel, onBlur, error, className }: TInputPasswordProps) {
  const [isEditable, setIsEditable] = useState(false);

  const startEditing = () => setIsEditable(true);
  const cancelEditing = () => {
    onCancel();
    setIsEditable(false);
  };
  const confirmChanges = () => {
    onConfirm();
    setIsEditable(false);
  };

  return (
    <div className={cn(styles.container, className)}>
      <Input
        className={styles.input}
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={error}
        readOnly={isEditable === false}
      />

      <div className={styles.controls}>
        {isEditable ? (
          <>
            <CheckOutlined onClick={confirmChanges} className={styles.checkmark} />
            <CloseOutlined onClick={cancelEditing} className={styles.cross} />
          </>
        ) : (
          <EditOutlined onClick={startEditing} />
        )}
      </div>
    </div>
  );
}
