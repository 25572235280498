import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { IPhoneRequest, IPhoneResponse } from '../model/type';

export const phonesApi = createApi({
  reducerPath: 'phonesApi',
  baseQuery,
  endpoints: (builder) => ({
    putPhone: builder.mutation<IPhoneResponse, IPhoneRequest>({
      query: ({ panelType, phoneId, body }) => ({
        url: `/${panelType}/phones/${phoneId}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { usePutPhoneMutation } = phonesApi;
