import { Card, Col, Form, Input, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { CurrencyCodeSelector } from 'features/CurrencyCodeSelector';
import { TransactionTypeSelector } from 'features/TransactionTypeSelector';
import { emptyStringToUndefined } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';

import { useFiltersLogic } from '../../../model/useFiltersLogic';

import styles from './filters.module.scss';

export function Filters() {
  const logic = useFiltersLogic();

  return (
    <Card title='Фильтр'>
      <Form layout={'vertical'}>
        <Row gutter={16}>
          <Col span={8}>
            <FormItem label='UID' className={styles.form_item}>
              <Input
                value={logic.transactionIdFilter}
                onChange={(e) => emptyStringToUndefined(e.target.value, logic.setTransactionIdFilter)}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Номер счета' className={styles.form_item}>
              <Input value={logic.walletIdFilter} onChange={(e) => emptyStringToUndefined(e.target.value, logic.setWalletIdFilter)} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Код валюты' className={styles.form_item}>
              <CurrencyCodeSelector
                value={logic.currencyCodeFilter ?? 'Все'}
                onChange={(value) => emptyStringToUndefined(value, logic.setCurrencyCodeFilter)}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <FormItem label='Тип транзакции' className={styles.form_item}>
              <TransactionTypeSelector
                value={logic.transactionTypeFilter ?? 'Все'}
                onChange={(value) => emptyStringToUndefined(value, logic.setTransactionTypeFilter)}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div className={styles.filter_btns}>
        <Button onClick={logic.applyFilters}>Применить</Button>
        <Button onClick={logic.resetFilters}>Отменить</Button>
      </div>
    </Card>
  );
}
