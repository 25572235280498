import { TabsProps } from 'antd';
import { CommissionsTable } from 'features/CommissionsTable';
import { MarkupTable } from 'features/MarkupTable';
import { useSearchParams } from 'react-router-dom';
import { WalletControlTabs } from 'widgets/WalletControlTabs';

export function Rate() {
  const [searchParams, setSearchParams] = useSearchParams();

  const tabs: TabsProps['items'] = [
    {
      key: 'commissions',
      label: <div onClick={() => setSearchParams(searchParams.toString().replace('&tab=markups', '&tab=commissions'))}>Комиссии</div>,
      children: <CommissionsTable />,
    },
    {
      key: 'markups',
      label: <div onClick={() => setSearchParams(searchParams.toString().replace('&tab=commissions', '&tab=markups'))}>Наценка</div>,
      children: <MarkupTable />,
    },
  ];

  return <WalletControlTabs tabs={tabs} />;
}
