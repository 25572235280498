import { useAppDispatch } from 'app/GlobalRedux/store';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { encodeToBase64 } from 'utils/encodeToBase64';
import { useAppMessage } from 'utils/messageContext';
import { useChangeUserMutation, useGetUserQuery, userApi } from 'widgets/User';

export function useChangeUserPasswordLogic() {
  const params = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { showError, showSuccess } = useAppMessage();

  const [newPassword, setNewPassword] = useState('');
  const [putNewUserData, putNewUserDataResponse] = useChangeUserMutation();

  const { data } = useGetUserQuery({
    panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });

  const confirmChanges = () => {
    putNewUserData({
      body: { password: encodeToBase64(newPassword) },
      panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      userId: data?.data?.userId ?? 'frontend error',
    });
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  useEffect(() => {
    if (putNewUserDataResponse.isError) showError(putNewUserDataResponse.error as IResponseError);
  }, [putNewUserDataResponse.isError, putNewUserDataResponse.error]);

  useEffect(() => {
    if (putNewUserDataResponse.isSuccess) {
      showSuccess('Пароль изменен успешно');
      setNewPassword('');
      dispatch(userApi.util.invalidateTags(['User']));
    }
  }, [putNewUserDataResponse.isSuccess]);

  return {
    confirmChanges,
    newPassword,
    handleChangePassword,
  };
}
