import { Content } from 'antd/es/layout/layout';
import { useOutlet } from 'react-router-dom';

import styles from './wallet.module.scss';

export function WalletPage() {
  const outlet = useOutlet();

  return (
    <Content className={styles.wallet}>
      <div>{outlet || <div>Use the menu for navigation</div>}</div>
    </Content>
  );
}
