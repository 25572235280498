import type { HTMLProps, PropsWithChildren, ReactNode } from 'react';
import { cn } from 'utils/classnames';

import styles from './modalTitle.module.scss';

interface Props extends PropsWithChildren<HTMLProps<HTMLHeadingElement>> {
  stub?: string | ReactNode;
}

function ModalTitle({ children, className, ...props }: Props) {
  return (
    <h3 className={cn(styles.title, className)} {...props}>
      {children}
    </h3>
  );
}

export default ModalTitle;
