import { useAppSelector } from 'app/GlobalRedux/store';
import { NavLink } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';

import styles from './notFound.module.scss';

export function NotFoundPage() {
  return (
    <section className={styles.container}>
      <div>
        <div>
          <br />
          <h1>404 | This page could not be found.</h1>
          <br />
          <Button className={styles.btn}>
            <NavLink to={'/'}>Home page</NavLink>
          </Button>
        </div>
      </div>
    </section>
  );
}
