import { TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  ITransaction,
  useLazyGetTransactionDataQuery,
  useLazyGetTransactionsCountQuery,
  useLazyGetTransactionsQuery,
  usePutTransactionDataMutation,
} from '../../../../entities/Transactions';
import { IResponseError } from '../../../../shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from '../../../../utils/messageContext';

export type DataIndex = keyof ITransaction;
type OnChange = NonNullable<TableProps<ITransaction>['onChange']>;
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

export function useFinOperationsTableLogic() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [editTransactionId, setEditTransactionId] = useState<ITransaction['transactionId'] | null>(null);
  const [transactionStatus, setTransactionStatus] = useState<string | null>(null);

  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const [currentPage, setCurrentPage] = useState(searchParams.get('pagination') ? Number(searchParams.get('pagination')) : 1);
  const [perPage, setPerPage] = useState(50);

  const { showError, showSuccess } = useAppMessage();

  const [getTransactions, getTransactionsResponse] = useLazyGetTransactionsQuery({ pollingInterval: 60000 });
  const [getTransactionsCount, getTransactionsCountResponse] = useLazyGetTransactionsCountQuery();
  const [getTransactionData, getTransactionDataResponse] = useLazyGetTransactionDataQuery();

  const [putTransaction] = usePutTransactionDataMutation();

  async function changeTransactionStatus(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      await putTransaction({
        body: { status: transactionStatus ?? 'frontend error' },
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        transactionId: editTransactionId ?? 'frontend error',
      });

      setEditTransactionId(null);
      showSuccess('Статус изменен');
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current ?? 0);

    searchParams.set('pagination', pagination.current?.toString() ?? '1');
    setSearchParams(searchParams);

    setSortedInfo(sorter as Sorts);
  };

  useEffect(() => {
    if (editTransactionId === null) return;

    getTransactionData({
      panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      transactionId: editTransactionId ?? 'frontend error',
    });
  }, [editTransactionId]);

  useEffect(() => {
    if (getTransactionDataResponse?.data?.data.status) setTransactionStatus(getTransactionDataResponse?.data.data.status);
  }, [getTransactionDataResponse?.data?.data.status]);

  useEffect(() => {
    if (getTransactionsResponse.isError) showError(getTransactionsResponse.error as IResponseError);
  }, [getTransactionsResponse.isError, getTransactionsResponse.error]);

  return {
    setTransactionStatus,
    transactionStatus,
    changeTransactionStatus,
    data: getTransactionsResponse.data,
    setEditTransactionId,
    editTransactionId,
    isLoading: getTransactionsResponse.isLoading,
    isFetching: getTransactionsResponse.isFetching,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    sortedInfo,
    handleChange,
    transactionsCount: getTransactionsCountResponse.data?.total,
    getTransactions,
    getTransactionsResponse,
    getTransactionsCount,
    getTransactionsCountResponse,
  };
}

export type TFinOperationsTableLogic = ReturnType<typeof useFinOperationsTableLogic>;
