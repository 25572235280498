import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { ITransaction } from 'entities/Transactions';
import { createContext } from 'react';
import { NavLink } from 'react-router-dom';
import { operationTypeMapping, round, transactionStatusMapping, transactionTypeMapping } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import { TFinOperationsTableLogic, useFinOperationsTableLogic } from '../../model/hooks/useFinOperationsTableLogic';
import { ChangeFinOperationStatusWindow, optionsMapper } from '../ChangeFinOperationStatusWindow/ChangeFinOperationStatusWindow';
import { Filters } from '../Filters/Filters';

export const FinOperationsContext = createContext<TFinOperationsTableLogic | null>(null);

export const changeableStatuses = Object.keys(optionsMapper);

export function FinOperationsTable() {
  const logic = useFinOperationsTableLogic();

  const columns: ColumnsType<ITransaction> = [
    {
      title: 'UID',
      dataIndex: 'transactionId',
      key: 'transactionId',
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => transformDateToUserFriendly(date),
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      sortOrder: logic.sortedInfo.columnKey === 'createdAt' ? logic.sortedInfo.order : null,
    },
    {
      title: 'Тип операции',
      dataIndex: 'operationType',
      key: 'operationType',
      sorter: (a, b) => a.transactionType.localeCompare(b.transactionType),
      sortOrder: logic.sortedInfo.columnKey === 'transactionType' ? logic.sortedInfo.order : null,
      render: (value: keyof typeof operationTypeMapping) => operationTypeMapping[value],
    },
    {
      title: 'Метод',
      dataIndex: 'payMethod',
      key: 'payMethod',
      // sorter: (a, b) => a.transactionType.localeCompare(b.transactionType),
      // sortOrder: logic.sortedInfo.columnKey === 'transactionType' ? logic.sortedInfo.order : null,
      // render: (value) => transactionTypeMapping[value],
    },
    {
      title: 'Profile ID',
      dataIndex: 'profileId',
      key: 'profileId',
    },
    {
      title: 'Баланс',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => `${round(amount)}`,
      sorter: (a, b) => +a.amount - +b.amount,
      sortOrder: logic.sortedInfo.columnKey === 'amount' ? logic.sortedInfo.order : null,
    },
    {
      title: 'Валюта',
      dataIndex: 'currencyCode',
      key: 'currencyCode',
      sorter: (a, b) => a.currencyCode.localeCompare(b.currencyCode),
      sortOrder: logic.sortedInfo.columnKey === 'currencyCode' ? logic.sortedInfo.order : null,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortOrder: logic.sortedInfo.columnKey === 'status' ? logic.sortedInfo.order : null,
      render: (value: keyof typeof transactionStatusMapping) => transactionStatusMapping[value],
    },
    {
      title: 'Подробнее',
      dataIndex: 'more',
      key: 'more',
      render: (_, record) => {
        return (
          <NavLink to={`${window.location.pathname.replace('/cash', '/finops')}/${record.transactionId}`}>
            <Button>Открыть</Button>
          </NavLink>
        );
      },
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'btn',
      render: (_, record) => (
        <Button disabled={!changeableStatuses.includes(record.status)} onClick={() => logic.setEditTransactionId(record.transactionId)}>
          Изменить статус
        </Button>
      ),
    },
  ];

  return (
    <FinOperationsContext.Provider value={logic}>
      <Filters />

      <Table
        rowKey={(row) => row.transactionId}
        columns={columns}
        dataSource={logic.data?.list}
        loading={logic.isLoading || logic.isFetching}
        onChange={logic.handleChange}
        scroll={{ x: 'scroll' }}
        pagination={{
          position: ['topRight', 'bottomRight'],
          showSizeChanger: true,
          current: logic.currentPage,
          pageSize: logic.perPage,
          onShowSizeChange: (currentPage, amount) => {
            logic.setCurrentPage(currentPage);
            logic.setPerPage(amount);
          },
          total: logic.transactionsCount,
          showTotal: (value) => `Всего финансовых операций: ${value}`,
          locale: { items_per_page: 'на стр.' },
        }}
      />

      <ChangeFinOperationStatusWindow
        isOpened={Boolean(logic.editTransactionId)}
        onClose={() => logic.setEditTransactionId(null)}
        onSubmit={logic.changeTransactionStatus}
        editTransactionId={logic.editTransactionId ?? 'no id'}
        selectedStatus={logic.transactionStatus ?? 'no status'}
        onChange={(value: string) => logic.setTransactionStatus(value)}
      />
    </FinOperationsContext.Provider>
  );
}
