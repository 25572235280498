import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { IEmailRequest, IEmailResponse } from '../model/type';

export const emailsApi = createApi({
  reducerPath: 'emailsApi',
  baseQuery,
  endpoints: (builder) => ({
    putEmail: builder.mutation<IEmailResponse, IEmailRequest>({
      query: ({ panelType, emailId, body }) => ({
        url: `/${panelType}/emails/${emailId}`,
        method: 'PUT',
        body,
      }),
    }),
    confirmEmail: builder.mutation<{ message: string }, Omit<IEmailRequest, 'body'>>({
      query: ({ panelType, emailId }) => ({
        url: `/${panelType}/emails/${emailId}/confirm`,
        method: 'PUT',
      }),
    }),
    resendEmail: builder.mutation<{ message: string }, Omit<IEmailRequest, 'body'>>({
      query: ({ panelType, emailId }) => ({
        url: `/${panelType}/emails/${emailId}/resend`,
        method: 'POST',
      }),
    }),
  }),
});

export const { usePutEmailMutation, useConfirmEmailMutation, useResendEmailMutation } = emailsApi;
