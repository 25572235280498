import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React from 'react';
import { TClassName } from 'shared/types/Types/TClassName';
import { cn } from 'utils/classnames';

import styles from './checkbox.module.scss';

interface CheckboxProps {
  className?: TClassName;
  onChange: (e: CheckboxChangeEvent) => void;
  children?: React.ReactNode;
}

function Checkbox({ className, onChange, children }: CheckboxProps) {
  return (
    <AntCheckbox onChange={onChange} className={cn(styles.checkbox, className)}>
      {children}
    </AntCheckbox>
  );
}

export default Checkbox;
