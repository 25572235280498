import { Col, Form, Row, Skeleton } from 'antd';
import Card from 'antd/es/card/Card';
import FormItem from 'antd/es/form/FormItem';
import { Button } from 'shared/ui/Button/Button';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';
import Input from 'shared/ui/Input/Input';
import { cn } from 'utils/classnames';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import { TUserPersonalData } from '../../model/useUserPersonalDataLogic';

import styles from './secondSection.module.scss';

export function SecondSection({ logic }: { logic: TUserPersonalData }) {
  return (
    <Card>
      <Form onFinish={logic.putUserData} layout={'vertical'} className='login-form'>
        <Row gutter={16}>
          <Col span={8}>
            <FormItem label='User ID' className={styles.form_item}>
              <Input placeholder='ID' value={logic.data?.data?.userId ?? ''} readOnly disabled />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem label='Имя' className={styles.form_item}>
              <Input name='name' placeholder='Имя' value={logic.userData?.name ?? ''} onChange={logic.changeUserData} />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem label='Фамилия' className={styles.form_item}>
              <Input name='surname' placeholder='Фамилия' value={logic.userData?.surname ?? ''} onChange={logic.changeUserData} />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <FormItem label='Страна' className={styles.form_item}>
              <Input name='countryName' placeholder='Страна' value={logic.userData?.countryName ?? ''} onChange={logic.changeUserData} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Город' className={styles.form_item}>
              <Input name='town' placeholder='Город' value={logic.userData?.town ?? ''} onChange={logic.changeUserData} />
            </FormItem>
          </Col>

          <Col span={8}>
            <FormItem label='Адрес' className={styles.form_item}>
              <Input name='address' placeholder='Адрес' value={logic.userData?.address ?? ''} onChange={logic.changeUserData} />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <FormItem label='Дата и время регистрации' className={styles.form_item}>
              <Input value={logic.userData?.createdAt ? transformDateToUserFriendly(logic.userData?.createdAt) : ''} readOnly disabled />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Дата и время подтверждения' className={styles.form_item}>
              <Input value={logic.userData?.confirmedAt ? transformDateToUserFriendly(logic.userData?.confirmedAt) : ''} readOnly disabled />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Дата и время обновления' className={styles.form_item}>
              <Input value={logic.userData?.updatedAt ? transformDateToUserFriendly(logic.userData?.updatedAt) : ''} readOnly disabled />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <FormItem label='Язык' className={styles.form_item}>
              <CustomSelect
                id={'locales-select'}
                className={styles.net_select}
                value={logic.locales?.list?.find((locale) => locale.localeCode === logic.userData?.lang)?.name}
                onChange={(value) => logic.changeUserData({ target: { value, name: 'lang' } } as React.ChangeEvent<HTMLInputElement>)}
                options={
                  logic.locales?.list?.map((item) => ({ value: item.localeCode, label: item.name })) ?? [{ value: undefined, label: '' }]
                }
                error={false}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Активен' className={styles.form_item}>
              <Input value={logic.userData?.isEnabled ? 'Да' : 'Нет'} readOnly disabled />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label='Общий баланс в USD' className={styles.form_item}>
              {logic.isBalanceLoading ? (
                <Skeleton.Input active />
              ) : (
                <Input placeholder='Общий баланс в USD' value={logic.balanceData?.data?.balance ?? ''} readOnly disabled />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <FormItem label='Заблокирован до' className={styles.form_item}>
              <Input value={logic.userData?.blockedUntil ? transformDateToUserFriendly(logic.userData?.blockedUntil) : ''} readOnly disabled />
            </FormItem>
          </Col>
        </Row>

        <Col span={24} className={styles.btn_column}>
          <Button className={cn(styles.change_btn, logic.isFormChanged && styles.active_btn)}>Изменить</Button>
        </Col>
      </Form>
    </Card>
  );
}
