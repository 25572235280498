import { Form, Input, Switch, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { createContext } from 'react';
import { NavLink } from 'react-router-dom';
import { round } from 'shared/constants';
import { Button } from 'shared/ui/Button/Button';

import { CustomColumnType, IRate } from '../../model/types';
import { TRatesTableLogic, useRatesTableLogic } from '../../model/useRatesTableLogic';
import { EditableCell } from '../EditableCell/EditableCell';
import { EditableRow } from '../EditableRow/EditableRow';
import { Filters } from '../Filters/Filters';

import styles from './ratesTable.module.scss';

export const RatesContext = createContext<TRatesTableLogic | null>(null);

export function RatesTable() {
  const logic = useRatesTableLogic();

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const defaultColumns: CustomColumnType<IRate>[] = [
    {
      title: 'ID',
      dataIndex: 'rateId',
      key: 'rateId',
    },
    {
      title: 'Из валюты',
      dataIndex: 'currencyCodeFrom',
      key: 'currencyCodeFrom',
      sorter: (a, b) => a.currencyCodeFrom.localeCompare(b.currencyCodeFrom),
      sortOrder: logic.sortedInfo.columnKey === 'currencyCodeFrom' ? logic.sortedInfo.order : null,
    },
    {
      title: 'В валюту',
      dataIndex: 'currencyCodeTo',
      key: 'currencyCodeTo',
      sorter: (a, b) => a.currencyCodeTo.localeCompare(b.currencyCodeTo),
      sortOrder: logic.sortedInfo.columnKey === 'currencyCodeTo' ? logic.sortedInfo.order : null,
    },
    {
      title: 'Авто значение',
      dataIndex: 'rateAuto',
      key: 'rateAuto',
      render: (value) => `${round(value)}`,
      sorter: (a, b) => +a.rateAuto - +b.rateAuto,
      sortOrder: logic.sortedInfo.columnKey === 'rateAuto' ? logic.sortedInfo.order : null,
    },
    {
      title: 'Фикс. значение',
      dataIndex: 'rateManual',
      key: 'rateManual',
      render: (value) => (
        <Form.Item style={{ margin: 0 }}>
          <Input value={round(value)} className={styles.editable_cell_item} />
        </Form.Item>
      ),
      sorter: (a, b) => +a.rateManual - +b.rateManual,
      sortOrder: logic.sortedInfo.columnKey === 'rateManual' ? logic.sortedInfo.order : null,
      editable: true,
    },
    {
      title: 'Фикс. курс',
      dataIndex: 'isManual',
      key: 'isManual',
      render: (isManual, record) => <Switch checked={isManual} onClick={() => logic.handleIsManualSwitch(isManual, record)} />,
    },
    {
      title: 'Включен',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      render: (isEnabled, record) => <Switch checked={isEnabled} onClick={() => logic.handleIsEnableSwitch(isEnabled, record)} />,
    },
    {
      title: 'Действия',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <NavLink
          to={`${window.location.pathname}/${record.rateId}?rateFrom=${record.currencyCodeFrom}&rateTo=${record.currencyCodeTo}&tab=commissions`}
        >
          <Button>Открыть</Button>
        </NavLink>
      ),
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record: IRate) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  return (
    <RatesContext.Provider value={logic}>
      <Filters />

      <Table
        rowKey={(row) => row.rateId}
        components={components}
        columns={columns as ColumnsType<IRate>}
        onChange={logic.handleChange}
        scroll={{ x: 'scroll' }}
        pagination={{
          position: ['topRight', 'bottomRight'],
          showSizeChanger: true,
          current: logic.currentPage,
          pageSize: logic.perPage,
          onShowSizeChange: (currentPage, amount) => {
            logic.setCurrentPage(currentPage);
            logic.setPerPage(amount);
          },
          total: logic.ratesCounter,
          showTotal: (value) => `Всего валютных курсов: ${value} `,
          locale: { items_per_page: 'на стр.' },
        }}
        dataSource={logic.filteredData}
        loading={logic.isLoading || logic.isFetching}
      />
    </RatesContext.Provider>
  );
}
