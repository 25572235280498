import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { ILocalesRequest, ILocalesResponse } from '../model/type';

export const localesApi = createApi({
  reducerPath: 'localesApi',
  baseQuery,
  endpoints: (builder) => ({
    getLocales: builder.query<ILocalesResponse, ILocalesRequest>({
      query: (params) => {
        const updatedParams = {
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
        };

        return {
          url: `/${params.panelType}/locales`,
          params: updatedParams,
        };
      },
    }),
  }),
});

export const { useGetLocalesQuery } = localesApi;
