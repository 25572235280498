import { useConfirmEmailMutation, usePutEmailMutation, useResendEmailMutation } from 'entities/Emails';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';
import { useGetUserQuery } from 'widgets/User';

export function useChangeUserEmail() {
  const params = useParams();
  const [newEmail, setNewEmail] = useState('');
  const [putEmail, putEmailResponse] = usePutEmailMutation();
  const { showError, showSuccess } = useAppMessage();

  const { data } = useGetUserQuery({
    panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });

  const [confirmEmail, confirmEmailResponse] = useConfirmEmailMutation();
  const [resendEmail, resendEmailResponse] = useResendEmailMutation();

  const confirmChanges = () => {
    if (newEmail === data?.data?.email) return showError({ data: { message: 'Внесите изменения' }, status: 100 });
    putEmail({
      body: {
        email: newEmail,
      },
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      emailId: data?.data?.emailId ?? 'frontend error',
    });
  };

  const handleResendEmail = () => {
    resendEmail({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      emailId: data?.data?.emailId ?? 'frontend error',
    });
  };

  const handleConfirmEmail = () => {
    confirmEmail({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      emailId: data?.data?.emailId ?? 'frontend error',
    });
  };

  useEffect(() => {
    setNewEmail(data?.data?.email ?? '');
  }, [data]);

  useEffect(() => {
    if (putEmailResponse.isError) showError(putEmailResponse.error as IResponseError);
  }, [putEmailResponse.isError, putEmailResponse.error]);

  useEffect(() => {
    if (putEmailResponse.isSuccess) showSuccess('Email изменен');
  }, [putEmailResponse.isSuccess]);

  useEffect(() => {
    if (resendEmailResponse.isSuccess) showSuccess(resendEmailResponse.data.message);
  }, [resendEmailResponse.isSuccess]);

  useEffect(() => {
    if (confirmEmailResponse.isSuccess) showSuccess(confirmEmailResponse.data.message);
  }, [confirmEmailResponse.isSuccess]);

  return {
    data,
    newEmail,
    confirmChanges,
    setNewEmail,
    handleResendEmail,
    handleConfirmEmail,
  };
}
