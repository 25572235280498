import { Form, Input, InputRef } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { CurrencyInput } from 'shared/ui/CurrencyInput';
import { useAppMessage } from 'utils/messageContext';

import { CloseCircleTwoTone, SaveTwoTone } from '@ant-design/icons';

import { usePutRateMutation } from '../../api/ratesApi';
import { IRate } from '../../model/types';
import { EditableContext } from '../EditableRow/EditableRow';

import styles from './editableCell.module.scss';

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof IRate;
  record: IRate;
  handleSave: (record: IRate) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  const { showError, showSuccess } = useAppMessage();
  const [putRate] = usePutRateMutation();

  useEffect(() => {
    if (editing && inputRef.current) inputRef.current!.focus();
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      await putRate({
        panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        rateId: record.rateId ?? 'frontend error',
        body: {
          isManual: true,
          rateManual: values.rateManual,
        },
      }).unwrap();

      showSuccess('Данные успешно обновлены');

      toggleEdit();
      // handleSave({ ...record, ...values });
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <div className={styles.editable_cell}>
        <Form.Item className={styles.form_item} name={dataIndex}>
          <CurrencyInput autoFocus className={styles.currency_input} />
        </Form.Item>
        <div className={styles.control_btns}>
          <SaveTwoTone className={styles.control_btn} onClick={save} />
          <CloseCircleTwoTone className={styles.control_btn} twoToneColor={'red'} onClick={toggleEdit} />
        </div>
      </div>
    ) : (
      <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
