import { Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { InputEditable } from 'shared/ui/InputEditable/InputEditable';

import { useChangeUserTelegramLogic } from '../model/useChangeUserTelegramLogic';

import styles from './changeUserTelegram.module.scss';

export function ChangeUserTelegram() {
  const { data, newTelegram, confirmChanges, setNewTelegram } = useChangeUserTelegramLogic();

  return (
    <Form onFinish={confirmChanges} layout='vertical' className={styles.form}>
      <FormItem label='Telegram' className={styles.form_item}>
        <InputEditable
          name='telegram'
          placeholder='Telegram'
          value={newTelegram}
          onConfirm={confirmChanges}
          onChange={(e) => setNewTelegram(e.target.value)}
          onCancel={() => setNewTelegram(data?.data?.telegram ?? '')}
        />
      </FormItem>
    </Form>
  );
}
