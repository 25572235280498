import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { PayHistoryContext } from '../ui/PayHistory/PayHistory';

export function useFiltersLogic() {
  const context = useContext(PayHistoryContext);
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [transactionIdFilter, setTransactionIdFilter] = useState<string | undefined>();
  const [walletIdFilter, setWalletIdFilter] = useState<string | undefined>(searchParams.get('wallet_number') ?? undefined);
  const [currencyCodeFilter, setCurrencyCodeFilter] = useState<string | undefined>();
  const [transactionTypeFilter, setTransactionTypeFilter] = useState<string | undefined>();

  const resetFilters = () => {
    setTransactionIdFilter(undefined);
    setWalletIdFilter(undefined);
    setCurrencyCodeFilter(undefined);
    setTransactionTypeFilter(undefined);

    getDataWithoutFilters();
  };

  const applyFilters = () => {
    context?.getTransactions({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      page: context?.currentPage,
      perPage: context?.perPage,
      userId: params.id,
      transactionType: transactionTypeFilter,
      transactionId: transactionIdFilter,
      currencyCode: currencyCodeFilter,
      walletId: walletIdFilter,
    });
    context?.getTransactionsCount({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      userId: params.id,
      transactionType: transactionTypeFilter,
      transactionId: transactionIdFilter,
      currencyCode: currencyCodeFilter,
      walletId: walletIdFilter,
    });
  };

  function getDataWithoutFilters() {
    context?.getTransactions({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      page: context?.currentPage,
      perPage: context?.perPage,
      userId: params.id,
    });
    context?.getTransactionsCount({
      panelType: window.location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      userId: params.id,
    });
  }

  useEffect(() => {
    // if we don't transfer a wallet number as a search parameter for the filter then just get a default list
    if (searchParams.get('wallet_number') === null) return getDataWithoutFilters();

    applyFilters();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [context?.currentPage]);

  return {
    transactionIdFilter,
    setTransactionIdFilter,
    walletIdFilter,
    setWalletIdFilter,
    currencyCodeFilter,
    setCurrencyCodeFilter,
    transactionTypeFilter,
    setTransactionTypeFilter,
    applyFilters,
    resetFilters,
  };
}
